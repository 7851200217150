import { CheckOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../../../../Components/Icon";
import {
  Input,
  DatePicker,
  Select,
  Button,
  Upload,
  // Modal,
  Form,
  notification,
  Spin,
  message,
} from "antd";
// import { CheckOutlined } from '@ant-design/icons';
import useUniversalFetch from "../../../../../../Hooks/useApi";
import { BASE_URL } from "../../../../../../Consts/variables";
import { useParams } from "react-router";

import "react-phone-number-input/style.css";

import moment from "moment";
import dayjs from "dayjs";
// import PhoneInput from "react-phone-number-input";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function TaskInner() {
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";

  const { taskId } = useParams();
  const { playgroundId } = useParams();
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [fileList, setFileList] = useState([]);

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));

  const userData = JSON.parse(localStorage.getItem("userData"));

  const {
    useFetchQuery,
    // useFetchMutation,
    usePatchMutation,
    // useDeleteMutation,
  } = useUniversalFetch();
  const {
    data: taskCardData,
    isLoading: isTaskCardDataLoading,
    // error: taskCardDataError,
    // isError: isTaskCardDataError,
  } = useFetchQuery({
    queryKey: "taskCard",
    token: token,
    url: `${BASE_URL}playground-section-task/${taskId}`,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const task = taskCardData?.data ? taskCardData?.data : [];
  console.log(task);
  useEffect(() => {
    if (!isTaskCardDataLoading) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
  }, [isTaskCardDataLoading, taskCardData]);

  const {
    data: salesData,
    // isLoading: isSalesDataLoading,
    // error: salesDataError,
    // isError: isSalesDataError,
    // isSuccess: isSuccessalesData,
  } = useFetchQuery({
    queryKey: "creators",
    token: token,
    url: `${BASE_URL}playground/${playgroundId}`,
  });

  const sales = salesData?.data ? salesData?.data : [];

  const {
    data: taskUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: TaskUpdate,
    // isLoading: isTaskUpdateLoading,
    error: taskUpdateError,
    isError: isTaskUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}playground-section-task`,
    method: "PATCH",
    token: token,
  });

  const handleEditTask = () => {
    TaskUpdate({
      id: taskId,
      data: {
        name: form.getFieldValue("project_name"),
        deadline: String(
          form.getFieldValue("date")
            ? new Date(form.getFieldValue("date")).getTime()
            : form.getFieldValue("date")
        ),
        order: 1,
        text_editor: form.getFieldValue("description"),
        playground_section: { id: task?.playground_section?.id },
        priority: form.getFieldValue("priority"),
        project: { id: 1 },
        files: fileList?.map((item) => ({ id: item.uid })),
        employees: form
          .getFieldValue("creators")
          ?.map((item) => ({ id: item })),
      },
    });
  };

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: taskUpdateData?.message,
      });
    } else if (isTaskUpdateError) {
      notification.error({
        message: "Task not updated",
        description: taskUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskUpdateError, taskUpdateData, taskUpdateError]);

  // useEffect(()=> {
  // if(playgroundId){
  form.setFieldValue("description", task?.text_editor);
  form.setFieldValue("project_name", task?.name);
  form.setFieldValue(
    "date",
    dayjs(
      String(moment(Number(task?.deadline)).format("DD/MM/YYYY")),
      dateFormat
    )
  );
  form.setFieldValue("priority", task?.priority || "medium");
  form.setFieldValue(
    "creators",
    task?.employees?.map((employee) => employee.id)
  );
  form.setFieldValue("project_name", task.name);
  // }
  // },[playgroundId])

  useEffect(() => {
    if (task?.files) {
      setFileList(
        task?.files?.map((file) => ({
          uid: file?.id,
          name: file?.file_name,
          status: "done",
          url: `${BASE_URL}${file?.mime_type}/${file?.path}`,
        }))
      );
    }
  }, [task]);

  const handleRemove = async (file) => {
    try {
      const response = await fetch(`${BASE_URL}file/${file.uid}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setFileList((prevList) =>
          prevList.filter((item) => item.uid !== file.uid)
        );
        message.success(`${file.name} deleted successfully.`);
      } else {
        throw new Error("Delete failed");
      }
    } catch (error) {
      message.error(`Failed to delete ${file.name}.`);
    }
  };

  const customUpload = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);

    fetch(`${BASE_URL}file`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        onSuccess(result);
        console.log(result.data);
        setFileList((prevList) => [
          ...prevList,
          {
            uid: result.data?.id,
            name: result.data?.file_name,
            status: "done",
            url: `${BASE_URL}${result.data?.mime_type}/${result.data?.path}`, // Assuming your API returns the URL of the uploaded file
          },
        ]);
        message.success(`${file.name} file uploaded successfully.`);
      })
      .catch((error) => {
        onError(error);
        message.error(`${file.name} file upload failed.`);
      });
  };

  const disablepermit =
    (userData?.role === "dev" || userData?.role === "sales") &&
    !userData?.is_lead
      ? true
      : false;

  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <div className="task_playground" style={{ opacity: opacity }}>
        <div className="page-heading">
          <div className="page-title">
            <div className="back-home trello_back_btn">
              <Link to={`/task-playgrounds/${playgroundId}`}>
                <div>
                  <Icon icon="drop-down" />
                  Back to main page
                </div>
              </Link>
            </div>
            <h1>Task playground</h1>
            <p>List of playgrounds</p>
          </div>
          <div className="upper-section">
            {!disablepermit && (
              <Button
                size="large"
                type="primary"
                icon={<CheckOutlined />}
                onClick={handleEditTask}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <div className="form-wrapper" style={{ width: "900px" }}>
            <div className="input-wrapper expenses-input-wrapper">
              <div className="single-input-item">
                <Form.Item
                  name="project_name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please fill the input",
                      // validateTrigger: 'onChange'
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <Input disabled={disablepermit} placeholder="Write name..." />
                </Form.Item>
              </div>

              <div className="single-input-item calendar">
                <Form.Item
                  name="date"
                  label="Deadline"
                  rules={[
                    {
                      required: true,
                      message: "Please input deadine!",
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <DatePicker
                    disabledDate={disablePastDates}
                    disabled={disablepermit}
                    placeholder="DD/MM/YYYY"
                    format={dateFormat}
                    onChange={(dateString) => {
                      form.setFieldsValue({ date: dateString });
                    }}
                    //   defaultValue={expense?.date ? moment(expense.date) : null}
                  />
                </Form.Item>
              </div>

              <div className="single-input-item">
                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[
                    {
                      required: true,
                      message: "Select agent...",
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <Select
                    disabled={disablepermit}
                    placeholder="Select agent..."
                  >
                    <Select.Option key={1} value={"high"}>
                      High
                    </Select.Option>
                    <Select.Option key={2} value={"medium"}>
                      Medium
                    </Select.Option>
                    <Select.Option key={3} value={"low"}>
                      Low
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="single-input-item multiple_select_trello">
              <Form.Item name="creators" label="Add creators">
                <Select
                  disabled={disablepermit}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  filterOption={(input, option) => {
                    const member = sales?.employees?.find(
                      (m) => m.id === option.value
                    );
                    const fullNameMatch = member?.fullname
                      ?.toLowerCase()
                      .includes(input.toLowerCase());
                    const jobTitleMatch = member?.job_title?.name
                      ?.toLowerCase()
                      .includes(input.toLowerCase());
                    return fullNameMatch || jobTitleMatch;
                  }}
                >
                  {sales?.employees?.map((member, index) => (
                    <Select.Option key={index} value={member.id}>
                      {member.fullname}{" "}
                      {member.job_title ? (
                        <strong>({member.job_title.name})</strong>
                      ) : null}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="textarea_input">
              <div className="single-input-item">
                <Form.Item
                  name="description"
                  label="Description"
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <TextArea
                    disabled={disablepermit}
                    placeholder="Controlled autosize"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="file_upload_trello">
              <Upload
                disabled={disablepermit}
                fileList={fileList}
                onRemove={handleRemove}
                customRequest={customUpload}
                listType="picture"
              >
                <Button disabled={disablepermit} icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
        </Form>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default TaskInner;
