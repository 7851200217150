import ApexChart from "../../../../Components/Apex-chart";
import BalanceChart from "../../../../Components/Apex-chart/balanceChart";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Spin, Button, Form, Input, Select, notification } from "antd";
import { LoadingOutlined, ArrowDownOutlined } from "@ant-design/icons";
import Icon from "../../../../Components/Icon";
import CustomModal from "../../Sales/calculator/resultModal";

function FinanceDashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  const reloadPage = () => {
    // This will navigate to the current location, forcing a reload
    navigate(location.pathname, { replace: true });
  };
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [totalResult, setTotalResult] = useState(0);
  const [currency, setCurrency] = useState();
  const [currency2, setCurrency2] = useState();
  const [isLoadBalance, setIsLoadBalance] = useState(false);
  const [method, setMethod] = useState();
  const [expenseType, setExpenseType] = useState();
  const [incomesType, setIncomeType] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState(0);

  const [form] = Form.useForm();

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation } = useUniversalFetch();

  const {
    data: expenseData,
    // isLoading: isExpenseLoading,
    // error: expenseError,
    // isError: isExpenseError,
    // isSuccess: isSuccesExpense,
    refetch: refetchExpense,
  } = useFetchQuery({
    queryKey: "expense-type",
    token: token,
    url: `${BASE_URL}setting/expense-type`,
    id: `?is_active=${true}`,
  });
  const expenseTypes = expenseData?.status === 200 ? expenseData.data : null;

  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    // error: balanceError,
    // isError: isBalanceError,
    // isSuccess: isSuccesBalance,
    refetch: refetchBalance,
  } = useFetchQuery({
    queryKey: "find-balance-by",
    token: token,
    url: `${BASE_URL}balance/find-one`,
    id: currency && method ? `?name=${currency}&method=${method}` : "",
  });
  const balance = balanceData?.status === 200 ? balanceData.data : null;

  useEffect(() => {}, []);

  const {
    data: incomeData,
    // isLoading: isIncomeLoading,
    // error: incomeError,
    // isError: isIncomeError,
    // isSuccess: isSuccesIncome,
    refetch: refetchIncome,
  } = useFetchQuery({
    queryKey: "income-type",
    token: token,
    url: `${BASE_URL}setting/income-type`,
  });
  const incomeTypes = incomeData?.status === 200 ? incomeData.data : null;

  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    // error: currencyError,
    // isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: "currency",
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : null;

  const {
    data: chartData,
    isLoading: isChartLoading,
    // error: chartError,
    // isError: isChartError,
    isSuccess: isChartSuccess,
    refetch: refetchIncomeChart,
  } = useFetchQuery({
    queryKey: "income-chart-data",
    token,
    url: `${BASE_URL}chart/income-chart`,
    id: incomesType ? `?id=${incomesType}` : "",
  });

  const {
    data: chartExpenseData,
    // isLoading: isChartExpenseLoading,
    // error: chartExpenseError,
    // isError: isChartExpenseError,
    // isSuccess: isChartExpenseSuccess,
    refetch: refetchExpenseChart,
  } = useFetchQuery({
    queryKey: "expense-chart",
    token,
    url: `${BASE_URL}chart/expense-chart`,
    id: expenseType ? `?id=${expenseType}` : "",
  });

  const {
    data: chartData2,
    // isLoading: isChartLoading2,
    // error: chartError2,
    // isError: isChartError2,
    // isSuccess: isChartSuccess2,
    refetch: refetchChart,
  } = useFetchQuery({
    queryKey: "chart-balance-data",
    token,
    url: `${BASE_URL}balance/find-all`,
    // id: date ? `?to=${date}` : "",
  });

  const chart = chartData?.status === 200 ? chartData.data : [];
  const chartExpense =
    chartExpenseData?.status === 200 ? chartExpenseData.data : [];
  const chartBalance = chartData2?.status === 200 ? chartData2.data : [];

  const {
    data: convertData,
    isSuccess: isSuccessConverted,
    mutate: Convert,
    // isLoading: isConvertLoading,
    error: convertError,
    isError: isConvertError,
  } = useFetchMutation({
    url: `${BASE_URL}balance/exchange-money`,
    method: "POST",
    token: token,
  });

  useEffect(() => {
    if (!isChartLoading && chartData && isChartSuccess) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChartLoading, chartData]);

  const handleOpenModal = () => {
    form.resetFields();
    setModalVisible(true);
  };

  const handleCloseModalConfirm = () => {
    setModalVisible(false);
    setTotalResult(0);
    setCurrency("");
    setMethod("");
  };

  const handleSubmit = () => {
    Convert({
      from_currency: currency,
      to_currency: form.getFieldValue("currency2"),
      from_method: method,
      to_method: form.getFieldValue("method2"),
      exchange_rate: Number(
        form.getFieldValue("current_rate").replace(/[^\d]/g, "")
      ),
      exchange_count: Number(form.getFieldValue("cost").replace(/[^\d]/g, "")),
    });
  };

  useEffect(() => {
    if (isSuccessConverted) {
      notification.success({
        // message: 'Project created',
        message: convertData?.message,
      });
      setModalVisible(false);

      form.resetFields();
      refetchExpense();
      // refetchBalance();
      setTotalResult(0);
      refetchIncome();
      refetchExpenseChart();
      refetchIncomeChart();
      refetchChart();
      setCurrency("");
      setMethod("");
    } else if (isConvertError) {
      notification.error({
        message: "Playground not created",
        description: convertError?.message,
      });
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConvertError, convertData, convertError]);

  const handleOkClear = () => {
    form
      .validateFields()
      .then(() => {
        handleSubmit();
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    setIsLoadBalance(true);

    setTimeout(() => {
      setSelectedBalance(balance?.[0]?.balance);
      setIsLoadBalance(isBalanceLoading);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance?.[0].balance]);

  useEffect(() => {
    if (currency2) {
      refetchBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency2]);

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ cost: val });

    if (currency?.toLowerCase() === "uzs") {
      const sum =
        Number((e.target.value || "0").toString().split(" ").join("")) /
        Number(
          (form.getFieldValue("current_rate") || "0")
            .toString()
            .split(" ")
            .join("")
        );
      sum && setTotalResult(sum);
    } else {
      const dollor =
        Number((e.target.value || "0").toString().split(" ").join("")) *
        Number(
          (form.getFieldValue("current_rate") || "0")
            .toString()
            .split(" ")
            .join("")
        );
      dollor && setTotalResult(dollor);
    }
  };

  const handleChangeCurrnetRate = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ current_rate: val });

    if (currency?.toLowerCase() === "uzs") {
      const sum =
        (form.getFieldValue("cost") || "0").toString().split(" ").join("") /
        Number((e.target.value || "0").toString().split(" ").join(""));
      sum && setTotalResult(sum);
    } else {
      const dollor =
        Number((e.target.value || "0").toString().split(" ").join("")) *
        (form.getFieldValue("cost") || "0").toString().split(" ").join("");
      dollor && setTotalResult(dollor);
    }
  };

  const validateNumber = (_, value) => {
    const maxBalance = Number(balance?.[0]?.balance) || 0; // Ensure a numeric value for balance
    const initalValue = value?.toString()?.split(" ")?.join("");
    // Check if the input value is not provided or it is a number between 0 and maxBalance

    if (
      !initalValue ||
      (Number(initalValue) >= 0 && Number(initalValue) <= maxBalance)
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        `The number must be between 0 and ${maxBalance
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`
      )
    );
  };

  useEffect(() => {
    reloadPage();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="projects_log">
      <div
        className="finance-dashboard balance-chart-box"
        style={{ opacity: opacity }}
      >
        <div className="page-title ">
          <div>
            <h1>Balance</h1>
            <p>Here you may track information of the Balance</p>
          </div>
          <div className="action_side">
            <Button
              type="primary"
              icon={<Icon icon="convert" />}
              iconPosition={"end"}
              onClick={handleOpenModal}
            >
              Convert
            </Button>
            {/* <DatePicker
              value={date ? dayjs(date) : null}
              onChange={(date, dateString) => {
                if (date) {
                  setDate(dayjs(date).valueOf()); // Convert to timestamp
                } else {
                  setDate(null);
                }
              }}
            /> */}
          </div>
          <CustomModal
            visible={modalVisible}
            onCancel={handleCloseModalConfirm}
            onOk={handleOkClear}
            recalculate={handleCloseModalConfirm}
            footerBtn={"Cencel"}
            heading={<h1>{"Convert"}</h1>}
            titleClass={"title min-title"}
          >
            <div className="task_create_modal_wrapper">
              <Form form={form} layout="vertical">
                <div className="twin_inputs">
                  <div className="single-input-item">
                    <Form.Item
                      className="currency_input"
                      name="currency"
                      label="Currency"
                      rules={[
                        {
                          required: true,
                          message: "required",
                        },
                      ]}
                      validateTrigger={["onBlur", "onSubmit", "onChange"]}
                    >
                      <Select
                        placeholder="UZS"
                        style={{ width: 90 }}
                        loading={isCurrencyLoading}
                        // disabled={currency === "uzs"}
                        onChange={(e) => {
                          setCurrency(e);
                          if (e?.toLowerCase() !== "uzs") {
                            form.setFieldValue("currency2", "UZS");
                          }
                        }}
                      >
                        {currencies
                          ?.filter((c) => c.is_active)
                          ?.map((activeCity) => (
                            <Select.Option
                              disabled={
                                activeCity.name ===
                                form.getFieldValue("currency2")
                              }
                              key={activeCity?.id}
                              value={activeCity?.name}
                            >
                              {activeCity.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="single-input-item">
                    <Form.Item
                      name="method"
                      label="To"
                      rules={[
                        {
                          required: true,
                          message: "Select method...",
                        },
                      ]}
                      validateTrigger={["onBlur", "onSubmit", "onChange"]}
                    >
                      <Select
                        placeholder="Select method..."
                        onChange={(e) => {
                          setMethod(e);
                        }}
                      >
                        <Select.Option value="cash">Cash</Select.Option>
                        <Select.Option value="card">Card</Select.Option>
                        <Select.Option value="bank">Bank</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {/* <div className="balance_wrapper">
                  <span>
                    Balance:{" "}
                    {isBalanceLoading ? (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        indicator={<LoadingOutlined spin />}
                        size="small"
                      />
                    ) : (
                      <>
                        {Number(balance[0]?.balance)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                        {` ${currency ? currency : ""}`}
                      </>
                    )}
                  </span>
                </div> */}

                <div className="balance_wrapper">
                  <span>
                    Balance:{" "}
                    {isLoadBalance ? (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        indicator={<LoadingOutlined spin />}
                        size="small"
                      />
                    ) : (
                      <>
                        {selectedBalance !== undefined
                          ? `${Number(selectedBalance)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${
                              currency || ""
                            }`
                          : "0"}
                      </>
                    )}
                  </span>
                </div>

                <div className="down_icon_wrapper">
                  <div className="down_icon">
                    <ArrowDownOutlined />
                  </div>
                </div>
                <div className="twin_inputs">
                  <div className="single-input-item">
                    <Form.Item
                      className="currency_input"
                      name="currency2"
                      label="Currency"
                      rules={[
                        {
                          required: true,
                          message: "required",
                        },
                      ]}
                      validateTrigger={["onBlur", "onSubmit", "onChange"]}
                    >
                      <Select
                        placeholder="UZS"
                        style={{ width: 90 }}
                        loading={isCurrencyLoading}
                        onChange={(e) => {
                          setCurrency2(e);
                          if (e?.toLowerCase() !== "uzs") {
                            setCurrency("UZS");
                            form.setFieldValue("currency", "UZS");
                          }
                        }}
                      >
                        {currencies
                          ?.filter((c) => c.is_active)
                          ?.map((activeCity) => (
                            <Select.Option
                              disabled={
                                activeCity.name ===
                                form.getFieldValue("currency")
                              }
                              key={activeCity?.id}
                              value={activeCity?.name}
                            >
                              {activeCity.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="single-input-item">
                    <Form.Item
                      name="method2"
                      label="To"
                      rules={[
                        {
                          required: true,
                          message: "Select method...",
                        },
                      ]}
                      validateTrigger={["onBlur", "onSubmit", "onChange"]}
                    >
                      <Select placeholder="Select method...">
                        <Select.Option value="cash">Cash</Select.Option>
                        <Select.Option value="card">Card</Select.Option>
                        <Select.Option value="bank">Bank</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="single-input-item"
                  style={{ marginTop: "20px" }}
                >
                  <Form.Item
                    label="Current currency rate"
                    name="current_rate"
                    className="salary-inner"
                    rules={[
                      {
                        required: true,
                        message: "Please fill",
                      },
                    ]}
                    validateTrigger={["onBlur", "onSubmit", "onChange"]}
                  >
                    <Input
                      value={form.getFieldValue("current_rate")}
                      onChange={handleChangeCurrnetRate}
                      placeholder="Add current rate..."
                      maxLength={10}
                      // disabled={disableRate}
                    />
                  </Form.Item>
                </div>
                <div
                  className="single-input-item"
                  style={{ marginTop: "20px" }}
                >
                  <Form.Item
                    label="Count"
                    name="cost"
                    className="salary-inner"
                    rules={[
                      {
                        required: true,
                        message: "Please fill",
                      },
                      {
                        validator: validateNumber, // Custom validation function
                      },
                    ]}
                    validateTrigger={["onBlur", "onSubmit", "onChange"]}
                  >
                    <Input
                      value={form.getFieldValue("cost")}
                      onChange={handleChange}
                      placeholder="Add count..."
                    />
                  </Form.Item>
                </div>
                <div className="balance_wrapper">
                  <span>
                    {" "}
                    {totalResult
                      ? Number(totalResult)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : "0"}{" "}
                    {form.getFieldValue("currency2")}
                  </span>
                </div>
              </Form>
            </div>
          </CustomModal>
        </div>
        <div className="chart-wrapper">
          {!!chartBalance
            ? Object.values(chartBalance)?.map((item, index) => (
                <>
                  <BalanceChart
                    data={item}
                    name={Object.keys(chartBalance)[index]}
                  />
                </>
              ))
            : null}
        </div>
      </div>
      {/* ------------- */}
      <div
        className="finance-dashboard"
        style={{ opacity: opacity, marginTop: "60px" }}
      >
        <div className="page-title">
          <div className="title_wrapper">
            <h1>Income</h1>
            <p>Here you may track financial information</p>
          </div>
          <Select
            defaultValue={""}
            placeholder="Select type..."
            onChange={(e) => {
              console.log(e);
              setIncomeType(e);
            }}
          >
            <Select.Option value={""}>{"All"}</Select.Option>
            <Select.Option value={"support"}>{"Support"}</Select.Option>
            {incomeTypes
              ?.filter((c) => c.is_active)
              .map((activeTitle) => (
                <Select.Option key={activeTitle.id} value={activeTitle.id}>
                  {activeTitle.name}
                </Select.Option>
              ))}
          </Select>
        </div>

        <div className="chart-wrapper">
          {!!chart.length ? (
            <ApexChart chart={chart} />
          ) : (
            <div>
              <ApexChart chart={chart} />
            </div>
          )}
        </div>
        <div className="page-title" style={{ marginTop: "60px" }}>
          <div className="title_wrapper">
            <h1>Expence</h1>
            <p>Here you may track financial information</p>
          </div>
          <Select
            defaultValue={""}
            placeholder="Select type..."
            onChange={(e) => {
              console.log(e);
              setExpenseType(e);
            }}
          >
            <Select.Option value={""}>{"All"}</Select.Option>
            {expenseTypes
              ?.filter((c) => c.is_active)
              .map((activeTitle) => (
                <Select.Option key={activeTitle.id} value={activeTitle.id}>
                  {activeTitle.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className="chart-wrapper">
          {!!chartExpense.length ? (
            <ApexChart type="expense" chart={chartExpense} />
          ) : (
            <div>
              <ApexChart type="expense" chart={chartExpense} />
            </div>
          )}
        </div>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </div>
  );
}

export default FinanceDashboard;
