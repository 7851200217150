// CustomModal.js
import React from "react";
import { Modal } from "antd";

class CustomModal extends React.Component {
  render() {
    const {
      visible,
      // title,
      onCancel,
      onOk,
      children,
      // recalculate,
      // footerBtn,
      // heading,
      // titleClass,
      width,
    } = this.props;

    return (
      <Modal
        open={visible}
        destroyOnClose
        className={width ? "small-modal mobile_modal" : "small-modal"}
        title={false}
        onCancel={onCancel}
        onOk={onOk}
        footer={null}
        width={580}
      >
        <div className="form-wrapper small_modal_wrapper">{children}</div>
      </Modal>
    );
  }
}

export default CustomModal;
