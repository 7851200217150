import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Switch,
  Popover,
  notification,
  Spin,
  ColorPicker,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import CountryModal from "./Action";
import Icon from "../../../../Components/Icon";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";

function CanbanType() {
  const [modalVisible, setModalVisible] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [isActive, setIsActive] = useState("");

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();

  const {
    data: integrationData,
    isLoading: isIntegrationLoading,
    // error: integrationError,
    // isError: isIntegrationError,
    isSuccess: isSuccesIntegration,
  } = useFetchQuery({
    queryKey: "CanbanType",
    token: token,
    url: `${BASE_URL}setting/canban-status`,
    id: `${isActive ? `?is_active=${isActive}` : ""}`,
  });

  useEffect(() => {
    if (!isIntegrationLoading && integrationData && isSuccesIntegration) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntegrationLoading, integrationData]);

  const data = integrationData?.status === 200 ? integrationData.data : null;

  const {
    data: integrationCreateData,
    isSuccess: isSuccessCreated,
    mutate: integrationCreate,
    // isLoading: isIntegrationCreateLoading,
    error: integrationCreateError,
    isError: isIntegrationCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}setting/canban-status`,
    method: "POST",
    token: token,
  });

  const {
    data: integrationUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: integrationUpdate,
    // isLoading: isIntegrationUpdateLoading,
    error: integrationUpdateError,
    isError: isIntegrationUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}setting/canban-status`,
    method: "PATCH",
    token: token,
  });

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: integrationCreateData?.message,
      });
    } else if (isIntegrationCreateError) {
      notification.error({
        message: "Integration not created",
        description: integrationCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntegrationCreateError, integrationCreateData, integrationCreateError]);

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: integrationUpdateData?.message,
      });
    } else if (isIntegrationUpdateError) {
      notification.error({
        message: "Integration not Updated",
        description: integrationUpdateError?.message,
      });
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isIntegrationUpdateError, integrationUpdateData, integrationUpdateError]);

  // const [open, setOpen] = useState(false);
  // const handleMenuClick = (e) => {
  //   if (e.key === "3") {
  //     setOpen(false);
  //   }
  // };
  // const handleOpenChange = (nextOpen, info) => {
  //   if (info.source === "trigger" || nextOpen) {
  //     setOpen(nextOpen);
  //   }
  // };

  if (activeTitle) {
  }

  const handleChange = (value) => {
    if (isActive !== "" && isActive === value) {
      setIsActive("");
    } else {
      setIsActive(value);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (_, record) => (
        <span>
          <ColorPicker value={record.color} disabled />
        </span>
      ),
    },
    {
      title: (
        <div className="status">
          <Popover
            placement="bottom"
            content={
              <div className="pop-over">
                <label className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={isActive === "true"}
                    onChange={() => handleChange("true")}
                  />
                  <span className="checkmark"></span>
                  <span>Active</span>
                </label>
                <label className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={isActive === "false"}
                    onChange={() => handleChange("false")}
                  />
                  <span className="checkmark"></span>
                  <span>Not active</span>
                </label>
              </div>
            }
          >
            <span>Status</span>
            <Icon icon="arrow-down" />
          </Popover>
        </div>
      ),
      dataIndex: "status",
      render: (status, record) => (
        <div className="switch">
          <Switch
            checked={record.is_active}
            onClick={() => {
              handleSwitchChange(record, !record.is_active);
            }}
          />
        </div>
      ),
      width: 300,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <span className="edit">
          <Icon
            onClick={() => {
              handleEdit(record);
              setActiveTitle(record);
            }}
            icon="edit"
          />
        </span>
      ),
    },
  ];

  const handleAdd = () => {
    setEditValues(null);
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditValues(record);
    setModalVisible(true);
  };

  const handleModalOk = (values, key) => {
    if (!editValues) {
      integrationCreate({
        name: values.name,
        color: values?.color,
      });
    }

    if (editValues) {
      integrationUpdate({
        id: editValues.id,
        data: {
          is_active: values.status,
          name: values.name,
          color: values?.color,
        },
      });
    }

    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleSwitchChange = (key, newStatus) => {
    // setActiveTitle(key)

    integrationUpdate({
      id: key.id,
      data: {
        is_active: newStatus,
      },
    });
  };

  return (
    <>
      <div className="settings-table" style={{ opacity: opacity }}>
        <Table
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={false}
        />
        <div className="bottom-side">
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            Add Canban Type
          </Button>
        </div>
        <CountryModal
          visible={modalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          initialValues={editValues}
        />
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default CanbanType;
