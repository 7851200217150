import Icon from "../Icon";
import Logo from "../../Assets/images/logo.svg";
// import Notification from "../../Assets/images/notification.svg"
import Avatar from "../../Assets/images/user.svg";
import Muz from "../../Assets/images/muzaffar.jpg";
// import Drop from "../../Assets/images/chevron-down.svg"
// import { useNavigate, useParams } from "react-router-dom"/
// import { useEffect } from "react"
// import { Avatar, Badge, Space } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
import { Popover } from "antd";

function Navbar({ setCollapsed, collapsed }) {
  const userData = JSON.parse(localStorage.getItem("userData"));

  //     const navigate = useNavigate()
  //     const {pathname} = useParams()
  // console.log(userData);
  //     useEffect(()=> {
  //         navigate(pathname, { replace: true });
  //     }, [])
  return (
    <nav>
      <div className="nav-wrapper">
        <div className="left-menu">
          <Icon icon="hamburger" onClick={() => setCollapsed(!collapsed)} />
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-profile">
          {/* <div className="notifications">
                        <img src={Notification} alt="Logo" />
                    </div> */}
          <div className="profile">
            <div className="avatar">
              {userData?.telegram === "@Muzaffarxon_Dev" ? (
                <div className="avatar_wrapper">
                  <Popover
                    placement="bottom"
                    title={userData?.fullname}
                    content={
                      <img className="my_image_big" src={Muz} alt="Logo" />
                    }
                  >
                    <img className="my_image" src={Muz} alt="Logo" />
                  </Popover>
                </div>
              ) : (
                <img className="avatar_img" src={Avatar} alt="Logo" />
              )}
            </div>
            <div className="info">
              <h3
                className={
                  userData?.telegram === "@shakhzod9910" ? "pro_account" : ""
                }
              >
                {userData?.fullname}
              </h3>
              <span>{userData?.role}</span>
              {userData?.is_lead === true ? (
                <span
                  style={{
                    marginLeft: "5px",
                    backgroundColor: "#32B4FD",
                    color: "#fff",
                  }}
                >
                  LEAD
                </span>
              ) : null}
            </div>
          </div>
          {/* <div className="drop-down">
                        <img src={Drop} alt="Logo" />
                    </div> */}
        </div>
      </div>
      <div className="mobile_nav_wrapper">
        <div className="left-menu">
          <img src={Logo} alt="Logo" />
          <Icon icon="hamburger" onClick={() => setCollapsed(!collapsed)} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
