import React, { useState, useEffect } from "react";
import {

  Modal,
  // Select,
  Button,
  Table,
  Popover,
  // message,
  notification,
  Spin,
  // DatePicker,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Icon from "../../../../Components/Icon";
import SupportModal from "./SupportModal";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";
// import Avatar from "../../../../Assets/images/user.svg";
// import moment from "moment";
import { RoleController } from "../../../../Hooks/role-controller";
import { useLocation } from "react-router-dom";
import Payment from "./SupportModal/payment";
import CustomModal from "../../Sales/calculator/resultModal";
// import { current } from '@reduxjs/toolkit';

const { confirm } = Modal;

const Support = () => {
  const { pathname } = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [currentPayment, setCurrentPayment] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");

  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const buildQueryString = () => {
    const params = new URLSearchParams({
      page: pagination.current,
      page_size: pagination.pageSize,
    });

    return `?${params.toString()}`;
  };

  const queryString = buildQueryString();
  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const {
    useFetchQuery,
    useFetchMutation,
    usePatchMutation,
    useDeleteMutation,
  } = useUniversalFetch();
  const {
    data: expenseData,
    isLoading: isExpenseDataLoading,
    // error: expenseDataError,
    // isError: isExpenseDataError,
    isSuccess: isSuccesExpenseData,
  } = useFetchQuery({
    queryKey: "support",
    token: token,
    url: `${BASE_URL}support`,
    id: queryString,
  });

  const expenses = expenseData ? expenseData : [];

  const {
    data: messageData,
    isLoading: isMessageDataLoading,
    // error: messageDataError,
    // isError: isMessageDataError,
    // isSuccess: isSuccesMessageData,
  } = useFetchQuery({
    queryKey: "getSms",
    token: token,
    url: `${BASE_URL}support/get-sms-text`,
    id: `/${currentMessage}`,
  });

  const messages = messageData?.status === 200 ? messageData.data : [];

  // const handleDateChange = (date, dateString) => {
  //   if (date && date.length === 2) {
  //     const startDateInMilliseconds = date[0] ? date[0].valueOf() : null;
  //     const endDateInMilliseconds = date[1] ? date[1].valueOf() : null;
  //     setDateFrom(startDateInMilliseconds);
  //     setDateTo(endDateInMilliseconds);
  //     console.log(startDateInMilliseconds);
  //     console.log(endDateInMilliseconds);
  //   } else {
  //     setDateFrom("");
  //     setDateTo("");
  //   }
  // };

  useEffect(() => {
    if (!isExpenseDataLoading && expenseData && isSuccesExpenseData) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseDataLoading, expenseData]);

  const {
    data: expenseCreateData,
    isSuccess: isSuccessCreated,
    mutate: expenseCreate,
    isLoading: isExpenseCreateLoading,
    error: expenseCreateError,
    isError: isExpenseCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}support`,
    method: "POST",
    token: token,
  });

  const {
    data: messageSendData,
    isSuccess: isSuccessSended,
    mutate: messageSend,
    // isLoading: isMessageSendLoading,
    error: messageSendError,
    isError: isMessageSendError,
  } = useFetchMutation({
    url: `${BASE_URL}support/send-sms`,
    method: "POST",
    token: token,
  });

  const {
    data: paymentCreateData,
    isSuccess: isSuccessPayment,
    mutate: paymentCreate,
    // isLoading: isPaymentCreateLoading,
    error: paymentCreateError,
    isError: isPaymentCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}support/payment`,
    method: "POST",
    token: token,
  });

  const {
    data: expenseUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: expenseUpdate,
    isLoading: isExpenseUpdateLoading,
    error: expenseUpdateError,
    isError: isExpenseUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}support`,
    method: "PATCH",
    token: token,
  });

  const {
    data: expenseDeleteData,
    isSuccess: isSuccessDelete,
    mutate: expenseDelete,
    // isLoading: isExpenseDeleteLoading,
    error: expenseDeleteError,
    isError: isExpenseDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}support`,
    method: "DELETE",
    token: token,
  });

  const showDeleteConfirm = (expenseId) => {
    confirm({
      title: "Are you sure delete this list ?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted expenses cannot be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      className: "delete-pop-up",
      onOk() {
        expenseDelete({
          id: expenseId.id,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: expenseUpdateData?.message,
      });
      setIsModalVisible(false);
    } else if (isExpenseUpdateError) {
      notification.error({
        message: "Data not updated",
        description: expenseUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseUpdateError, expenseUpdateData, expenseUpdateError]);

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: expenseDeleteData?.message,
      });
    } else if (isExpenseDeleteError) {
      notification.error({
        message: "Data not deleted",
        description: expenseDeleteError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseDeleteError, expenseDeleteData, expenseDeleteError]);

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: expenseCreateData?.message,
      });
      setIsModalVisible(false);
    } else if (isExpenseCreateError) {
      notification.error({
        message: "Data not created",
        description: expenseCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseCreateError, expenseCreateData, expenseCreateError]);

  // const { Search } = Input;

  const showEditModal = (expense) => {
    setCurrentExpense(expense);
    setIsEditMode(true);
    setIsModalVisible(true);
  };

  const showCreateModal = () => {
    setCurrentExpense(null);
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentExpense(null);
  };

  const handleModalOk = (values, key) => {
    paymentCreate({
      support: {
        id: currentPayment.id,
      },
      currency: {
        id: values.currency,
      },
      method: values.method,

      amount: Number(values.cost.replace(/[^\d]/g, "")),
    });
    setModalVisible(false);
  };

  useEffect(() => {
    if (isSuccessPayment) {
      notification.success({
        // message: 'Project created',
        message: paymentCreateData?.message,
      });
      setIsModalVisible(false);
    } else if (isPaymentCreateError) {
      notification.error({
        message: "Payment failed",
        description: paymentCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentCreateError, paymentCreateData, paymentCreateError]);

  useEffect(() => {
    if (isSuccessSended) {
      notification.success({
        // message: 'Project created',
        message: messageSendData?.message,
      });
      setConfirmModalVisible(false);
    } else if (isMessageSendError) {
      notification.error({
        message: "Message failed",
        description: messageSendError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageSendError, messageSendData, messageSendError]);

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handlePay = (record) => {
    setCurrentPayment(record);
    setModalVisible(true);
  };

  const handleSubmit = (expenseData, isEdit) => {
    if (!isEdit) {
      expenseCreate({
        project_name: expenseData.project_name,
        phone_number: String(expenseData.contact),
        pay_date: Number(expenseData.pay_date),
        comment: expenseData.comment,
        cost: Number(expenseData.cost.replace(/[^\d]/g, "")),
        currency: {
          id: expenseData.currency,
        },
      });
    } else {
      expenseUpdate({
        id: currentExpense.id,
        data: {
          project_name: expenseData.project_name,
          phone_number: String(expenseData.contact),
          pay_date: Number(expenseData.pay_date),
          comment: expenseData.comment,
          cost: Number(expenseData.cost.replace(/[^\d]/g, "")),
          currency: {
            id: expenseData.currency,
          },
        },
      });
    }
  };

  const handleOpenConfirmModal = (record) => {
    setConfirmModalVisible(true);
    setCurrentMessage(record.id);
  };

  const handleCloseModalConfirm = () => {
    setConfirmModalVisible(false);
  };

  const handleOkClear = () => {
    messageSend({
      support_id: currentMessage,
    });
  };

  const handleTableChange = (pagination) => {
    // Update pagination state to reflect the new current page
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const checkAccess = RoleController[userData?.role]?.edit?.find(
    (item) => item === pathname
  );

  const columns = [
    {
      title: "Project name",
      dataIndex: "",
      width: 180,
      render: (_, record) => <span>{record.project_name}</span>,
    },

    {
      title: "Contact",
      dataIndex: "",
      width: 160,
      render: (_, record) => (
        <div className="job">
          <span className="expense-type-raw">{record.phone_number}</span>
        </div>
      ),
    },
    {
      title: "Pay date",
      dataIndex: "",
      width: 120,
      render: (_, record) => <span>{record?.pay_date}</span>,
    },
    {
      title: "Balance",
      dataIndex: "",
      width: 130,
      render: (_, record) => {
        const balance = record?.balance;
        const formattedBalance = balance
          ? Number(balance)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "";

        const balanceStyle = {
          color: balance < 0 ? "#EB1F1F" : balance > 0 ? "#07A00D" : "black",
        };

        return <span style={balanceStyle}>{formattedBalance || 0}</span>;
      },
    },
    {
      title: "Pay type",
      dataIndex: "",
      width: 120,
      render: (_, record) => <span>{record?.currency?.name}</span>,
    },

    {
      title: "Comment",
      dataIndex: "",
      width: 180,
      render: (_, record) => {
        return (
          <span className="comment expense-comment">
            <Popover placement="bottom" content={record.comment}>
              <Button type="primary">{<p>{record.comment}</p>}</Button>
              <Icon icon="drop-down" />
            </Popover>
          </span>
        );
      },
    },
    {
      title: "Cost",
      dataIndex: "",
      width: 160,
      render: (_, record) => (
        <span>
          {record.cost
            ? Number(record.cost)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
              " " +
              record?.currency.name
            : ""}
        </span>
      ),
    },
    checkAccess
      ? {
          title: "Edit",
          dataIndex: "edit",
          width: 85,
          render: (_, record) => (
            <span className="edit">
              <Icon icon="edit" onClick={() => showEditModal(record)} />
              <Icon icon="card" onClick={() => handlePay(record)} />
              <Icon
                icon="message"
                onClick={() => handleOpenConfirmModal(record)}
              />
              <Icon icon="delete" onClick={() => showDeleteConfirm(record)} />
            </span>
          ),
        }
      : {},
  ];

  return (
    <>
      <div
        className="expenses employees projects_log"
        style={{ opacity: opacity }}
      >
        <div className="page-heading">
          <div className="page-title">
            <h1 className="">Support</h1>
            <p>Here you may track financial information</p>
          </div>
          <div className="upper-section">
            {(userData?.role === "hr" || userData?.role === "ceo") && (
              <div className="download-btn">
                <Button
                  size="large"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showCreateModal}
                >
                  Add Support
                </Button>
              </div>
            )}
          </div>
        </div>
        <CustomModal
          visible={confirmModalVisible}
          onCancel={handleCloseModalConfirm}
          onOk={handleOkClear}
          recalculate={handleCloseModalConfirm}
          footerBtn={"Cencel"}
          heading={<h1>Message</h1>}
          titleClass={"title min-title"}
        >
          {isMessageDataLoading}
          <div className="confirm-content">
            <blockquote>
              <div className="message-text-wrapper">
                <h4>{"Are you sure you want to send this message ?"}</h4>
                <br />
                {messages?.send_message}
              </div>
            </blockquote>
          </div>
        </CustomModal>
        <div className="table">
          <Table
            columns={columns}
            dataSource={expenseData?.data || []}
            loading={isExpenseDataLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: expenseData?.total_elements || 0, // Total records from the backend
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
            footer={() => (
              <div style={{ textAlign: "end" }}>
                <div className="total-salary">
                  <span className="amount-wrapper">
                    {expenses &&
                      expenses?.per_currency?.map((item) => (
                        <span className="">
                          {item.currency}:{" "}
                          {item?.total_cost
                            ? Number(item?.total_cost)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            : ""}
                        </span>
                      ))}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
        <SupportModal
          isLoading={isExpenseCreateLoading || isExpenseUpdateLoading}
          expense={currentExpense}
          isVisible={isModalVisible}
          onSave={handleSubmit}
          onCancel={handleCancel}
          isEditMode={isEditMode}
        />
        <Payment
          values={currentPayment}
          visible={modalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        />
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
};

export default Support;
