import './App.scss';
import Build from './Build';
// import Login from "../src/Build/Auth/Login"
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
// import { Navigate } from 'react-router';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userData = localStorage.getItem('userData');
  const token = localStorage.getItem('authToken');

  // useEffect(() => {
  //   setIsAuthenticated(!!token);
  // }, []);
const location = useLocation();
const { pathname } = location;

useEffect(()=> {
  if (token) {
    navigate(pathname)
  }else{
    navigate("/login")
  }
  
 
// eslint-disable-next-line react-hooks/exhaustive-deps
},[token,userData]) 


  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Build/>
      </div>
    </QueryClientProvider>
  );
}

export default App; 


