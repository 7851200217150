import React, { useState, useEffect } from "react";
import { Table, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CountryModal from "./Action";
import Icon from "../../../../Components/Icon";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";

function Wage() {
  const [modalVisible, setModalVisible] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  // const [isActive, setIsActive] = useState("");

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, usePatchMutation } = useUniversalFetch();

  const {
    data: wageData,
    isLoading: isWageLoading,
    // error: wageError,
    // isError: isWageError,
    isSuccess: isSuccesWage,
  } = useFetchQuery({
    queryKey: "wage",
    token: token,
    url: `${BASE_URL}setting/company-wage`,
  });

  useEffect(() => {
    if (!isWageLoading && wageData && isSuccesWage) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWageLoading, wageData]);

  const data = wageData?.status === 200 ? wageData.data : [];

  console.log(data);

  if (activeTitle) {
    console.log(1);
  }

  const {
    data: wageUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: wageUpdate,
    // isLoading: isWageUpdateLoading,
    error: wageUpdateError,
    isError: isWageUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}setting/company-wage`,
    method: "PATCH",
    token: token,
  });

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: wageUpdateData?.message,
      });
    } else if (isWageUpdateError) {
      notification.error({
        message: "Wage not Updated",
        description: wageUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWageUpdateError, wageUpdateData, wageUpdateError]);

  const columns = [
    {
      title: "Margin",
      dataIndex: "name",
      render: (_, record) => <span className="">{record?.percent + "%"}</span>,
    },
    {
      title: <span className="wage-edit">Action</span>,
      dataIndex: "action",
      render: (_, record) => (
        <span className="edit wage-edit">
          <Icon
            onClick={() => {
              handleEdit(record);
              setActiveTitle(record);
            }}
            icon="edit"
          />
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditValues(record);
    setModalVisible(true);
  };

  const handleModalOk = (values, key) => {
    wageUpdate({
      id: editValues.id,
      data: {
        percent: Number(values.wage),
        name: data?.name,
      },
    });
    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div className="settings-table" style={{ opacity: opacity }}>
        <Table
          columns={columns}
          dataSource={[data]}
          size="middle"
          pagination={false}
        />

        <CountryModal
          visible={modalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          initialValues={editValues}
        />
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default Wage;
