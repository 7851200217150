import React, { useState, useEffect } from "react";
import { Table, Button, Switch, Popover, notification, Spin } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import CountryModal from "./Action";
import Icon from "../../../../Components/Icon";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";

function Country() {
  const [modalVisible, setModalVisible] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [isActive, setIsActive] = useState("");

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();
  const {
    data: jobTitleData,
    isLoading: isJobTitleLoading,
    // error: jobTitleError,
    // isError: isJobTitleError,
    isSuccess: isSuccesjobTitle,
  } = useFetchQuery({
    queryKey: "jobTitle",
    token: token,
    url: `${BASE_URL}setting/job-title`,
    id: `${isActive ? `?is_active=${isActive}` : ""}`,
  });

  useEffect(() => {
    if (!isJobTitleLoading && jobTitleData && isSuccesjobTitle) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobTitleLoading, jobTitleData]);

  const data = jobTitleData?.status === 200 ? jobTitleData.data : null;

  const {
    data: jobTitleCreateData,
    isSuccess: isSuccessCreated,
    mutate: jobTitleCreate,
    // isLoading: isJobTitleCreateLoading,
    error: jobTitleCreateError,
    isError: isJobTitleCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}setting/job-title`,
    method: "POST",
    token: token,
  });

  const {
    data: jobTitleUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: jobTitleUpdate,
    // isLoading: isJobTitleUpdateLoading,
    error: jobTitleUpdateError,
    isError: isJobTitleUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}setting/job-title`,
    method: "PATCH",
    token: token,
  });

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: jobTitleCreateData?.message,
      });
    } else if (isJobTitleCreateError) {
      notification.error({
        message: "Job title not created",
        description: jobTitleCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobTitleCreateError, jobTitleCreateData, jobTitleCreateError]);

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: jobTitleUpdateData?.message,
      });
    } else if (isJobTitleUpdateError) {
      notification.error({
        message: "Job title not updated",
        description: jobTitleUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobTitleUpdateError, jobTitleUpdateData, jobTitleUpdateError]);

  // const [open, setOpen] = useState(false);
  // const handleMenuClick = (e) => {
  //   if (e.key === "3") {
  //     setOpen(false);
  //   }
  // };
  // const handleOpenChange = (nextOpen, info) => {
  //   if (info.source === "trigger" || nextOpen) {
  //     setOpen(nextOpen);
  //   }
  // };

  if (activeTitle) {
  }

  const handleChange = (value) => {
    if (isActive !== "" && isActive === value) {
      setIsActive("");
    } else {
      setIsActive(value);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
    },
    {
      title: (
        <div className="status">
          <Popover
            placement="bottom"
            content={
              <div className="pop-over">
                <label className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={isActive === "true"}
                    onChange={() => handleChange("true")}
                  />
                  <span className="checkmark"></span>
                  <span>Active</span>
                </label>
                <label className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={isActive === "false"}
                    onChange={() => handleChange("false")}
                  />
                  <span className="checkmark"></span>
                  <span>Not active</span>
                </label>
              </div>
            }
          >
            <span>Status</span>
            <Icon icon="arrow-down" />
          </Popover>
        </div>
      ),
      dataIndex: "status",
      render: (status, record) => (
        <div className="switch">
          <Switch
            checked={record.is_active}
            onClick={() => {
              handleSwitchChange(record, !record.is_active);
            }}
          />
        </div>
      ),
      width: 300,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <span className="edit">
          <Icon
            onClick={() => {
              handleEdit(record);
              setActiveTitle(record);
            }}
            icon="edit"
          />
        </span>
      ),
    },
  ];

  const handleAdd = () => {
    setEditValues(null);
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditValues(record);
    setModalVisible(true);
  };

  const handleModalOk = (values, key) => {
    if (!editValues) {
      jobTitleCreate({
        name: values.title,
      });
    }

    if (editValues) {
      jobTitleUpdate({
        id: editValues.id,
        data: {
          is_active: values.status,
          name: values.title,
        },
      });
    }

    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleSwitchChange = (key, newStatus) => {
    // setActiveTitle(key)

    jobTitleUpdate({
      id: key.id,
      data: {
        is_active: newStatus,
      },
    });
  };

  return (
    <>
      <div className="settings-table" style={{ opacity: opacity }}>
        <Table
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={false}
        />
        <div className="bottom-side">
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            Add Job Title
          </Button>
        </div>
        <CountryModal
          visible={modalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          initialValues={editValues}
        />
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default Country;
