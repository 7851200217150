import React, { useState } from "react";
import {
  Input,
  Modal,
  Form,
  Spin,
  notification,
  // message,
  Popconfirm,
} from "antd";
import { useEffect } from "react";
import Icon from "../../../../Components/Icon";
import useUniversalFetch from "../../../../Hooks/useApi";
import { BASE_URL } from "../../../../Consts/variables";
// import FormItem from 'antd/es/form/FormItem';
import { DownloadOutlined } from "@ant-design/icons";

const FileAdd = ({ isVisible, onCancel, files }) => {
  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchMutation, usePatchMutation, useDeleteMutation } =
    useUniversalFetch();

  const {
    data: fileCreateData,
    isSuccess: isSuccessCreated,
    mutate: fileCreate,
    isLoading: isFileCreateLoading,
    error: fileCreateError,
    isError: isFileCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}file`,
    method: "POST",
    token: token,
  });

  const {
    // data: employeeUpdateData,
    // isSuccess: isSuccessUpdated,
    mutate: employeeUpdate,
    // isLoading: isEmployeeUpdateLoading,
    // error: employeeUpdateError,
    // isError: isEmployeeUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}employee`,
    method: "PATCH",
    token: token,
  });

  const {
    // data: fileUpdateData,
    isSuccess: isFileSuccessUpdated,
    mutate: fileUpdate,
    // isLoading: isFileUpdateLoading,
    error: fileUpdateError,
    isError: isFileUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}file`,
    method: "PATCH",
    token: token,
  });

  const {
    // data: fileDeleteData,
    isSuccess: isSuccessDelete,
    mutate: fileDeleteMutation,
    // isLoading: isfileDeleteLoading,
    // error: fileDeleteError,
    // isError: isfileDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}file`,
    method: "DELETE",
    token: token,
  });

  const [employeeFiles, setEmployeeFiles] = useState(files?.files || []);
  const [previewFile, setPreviewFile] = useState(null);
  const [deletedId, setdeletedId] = useState(null);

  const handleCancelFile = () => {
    onCancel();

    employeeFiles?.length &&
      employeeUpdate({
        id: files?.id,
        data: {
          files: employeeFiles.map((file) => ({ id: file.id })),
        },
      });
  };

  function fileDelete({ id }) {
    setEmployeeFiles(
      employeeFiles
        ?.filter((el) => el.id !== id)
        ?.map((file) => ({ id: file.id }))
    );
    setdeletedId(id);
    fileDeleteMutation({
      id: id,
    });
  }

  useEffect(() => {
    setEmployeeFiles(
      employeeFiles
        ?.filter((el) => el.id !== deletedId)
        ?.map((file) => ({ id: file.id }))
    );
    // if (deletedId) {
    //     employeeFiles.length &&  employeeUpdate({
    //         id: files?.id,
    //         data:{
    //     files: employeeFiles?.filter((el) => el.id !== deletedId)?.map((file)=> ({id:file.id}))
    //     } })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedId, isSuccessDelete]);

  useEffect(() => {
    if (isFileSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: "Saved",
      });
    } else if (isFileUpdateError) {
      notification.error({
        message: "Not saved",
        description: fileUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileSuccessUpdated]);

  useEffect(() => {
    //     if (isSuccessCreated) {
    //     //   notification.success({
    //     //     // message: 'Project created',
    //     //     message: "Saved"
    //     //   });
    //     }
    //    else
    if (isFileCreateError) {
      notification.error({
        message: "Not saved",
        description: fileCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCreateData, fileCreateError, isSuccessCreated]);

  useEffect(() => {
    setEmployeeFiles(files?.files);
  }, [files, isVisible]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file?.name);
    fileCreate(formData);

    employeeFiles.length &&
      employeeUpdate({
        id: files?.id,
        data: {
          files: employeeFiles.map((file) => ({ id: file.id })),
        },
      });
  };

  useEffect(() => {
    if (isSuccessCreated) {
      setEmployeeFiles([...employeeFiles, fileCreateData?.data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreated]);

  // useEffect(()=>{
  //     if (isSuccessDelete) {
  //         // setEmployeeFiles(files.files)
  //         setEmployeeFiles(files.files?.filter((el) => el.id === file.id)?.map((file)=> ({id:file.id})))

  //       }

  // },[isSuccessDelete])

  const userData = JSON.parse(localStorage.getItem("userData"));

  const showModal = (file) => {
    setPreviewFile(file);
  };

  const handleCancel = () => {
    setPreviewFile(null);
  };

  return (
    <>
      <Modal
        open={isVisible}
        onCancel={handleCancelFile}
        destroyOnClose={true}
        width={770}
        className="small-modal"
        footer={[]}
      >
        {previewFile ? (
          <div onClick={handleCancel} className="modal-backdrop2">
            <div
              className="prewiev-modal2"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="modal-header">
                <h2 className="modal-title">{previewFile?.file_name}</h2>
                <a
                  href={`${
                    BASE_URL + previewFile?.mime_type + "/" + previewFile?.path
                  }`}
                  rel="noopener noreferrer"
                  download={previewFile.file_name}
                  className="download-selected_file"
                >
                  <DownloadOutlined />
                </a>
                <button className="cencel-file-popup" onClick={handleCancel}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="file-box">
                {previewFile?.mime_type === "image" ? (
                  <img
                    className="content-image"
                    src={getOfficeViewerUrl(previewFile)}
                    width={1000}
                    height={500}
                    alt={previewFile?.file_name}
                  />
                ) : (
                  <iframe
                  title="title"
                    className="content-image"
                    src={getOfficeViewerUrl(previewFile)}
                    frameborder="0"
                    width={1000}
                    height={500}
                  >
                    <p>word file</p>
                  </iframe>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-wrapper">
          <div className="min-title">
            <h1>Files</h1>
          </div>
          <Form layout="vertical">
            <ul>
              {employeeFiles &&
                employeeFiles.map((file, index, data) => {
                  const card = (
                    <FileCard
                      key={index}
                      item={files}
                      file={file}
                      employeeFiles={data}
                      fileUpdate={fileUpdate}
                      employeeUpdate={employeeUpdate}
                      fileDelete={fileDelete}
                      showModal={showModal}
                    />
                  );

                  return <>{card}</>;
                })}
              {userData?.role === "hr" || userData?.role === "ceo" ? (
                <li className="upload">
                  {isFileCreateLoading ? (
                    <label htmlFor="file">
                      <Spin size="large" />
                    </label>
                  ) : (
                    <label htmlFor="file">
                      <div className="plus">
                        <Icon icon="x" /> {/* Adjust icon if necessary */}
                      </div>
                      <span>Add file</span>
                      <input
                        id="file"
                        type="file"
                        accept=".doc,.docx,.pdf,.txt,.numbers,.pages,.xls,.xlsx,image/*"
                        onChange={handleFileChange}
                      />
                    </label>
                  )}
                </li>
              ) : null}
            </ul>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FileAdd;

const getOfficeViewerUrl = (file) => {
  if (
    file?.mime_type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    file?.mime_type === "application/msword" ||
    file.mime_type === "word" ||
    file.mime_type === "pdf"
  ) {
    // Word document
    return `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
      BASE_URL + file?.mime_type + "/" + file?.path
    )}`;
  } else if (
    file?.mime_type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file?.mime_type === "application/vnd.ms-excel" ||
    file?.mime_type === "excel"
  ) {
    // Excel document
    return `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
      BASE_URL + file?.mime_type + "/" + file?.path
    )}`;
  }
  // Other files can be directly linked for download or handled differently
  return `${BASE_URL}${file?.mime_type}/${file?.path}`;
};

function FileCard({
  item,
  file,
  employeeFiles,
  employeeUpdate,
  fileUpdate,
  fileDelete,
  showModal,
}) {
  const [fileName, setFileName] = useState(file.file_name);

  const onChange = (e) => {
    setFileName(e.target.value);
  };

  const confirm = (e) => {
    fileDelete({
      id: file.id,
    });
  };
  const cancel = (e) => {};
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <li>
      <div className="item-wrapper">
        {userData?.role === "hr" || userData?.role === "ceo" ? (
          <Popconfirm
            title="Delete the file"
            description="Are you sure to delete this file?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
            okType="danger"
          >
            <div className="delete">
              <Icon icon="x" />
            </div>
          </Popconfirm>
        ) : null}
        <div onClick={() => showModal(file)} className="file-img">
          {/* <a target="_blank" rel="noopener noreferrer" href={getOfficeViewerUrl(file)}> */}
          <Icon icon="pdf" />{" "}
          {/* Change this icon based on the file type if needed */}
          {/* </a> */}
        </div>

        <div className="file-name">
          {userData?.role === "hr" || userData?.role === "ceo" ? (
            <Input allowClear value={fileName} onChange={onChange} />
          ) : (
            <span>{fileName}</span>
          )}
          {userData?.role === "hr" || userData?.role === "ceo" ? (
            <Icon
              icon="checkk"
              onClick={() => {
                if (fileName === file.file_name) {
                  employeeFiles.length &&
                    employeeUpdate({
                      id: item?.id,
                      data: {
                        files: employeeFiles.map((file) => ({ id: file.id })),
                      },
                    });
                } else {
                  fileUpdate({
                    id: file?.id,
                    data: {
                      file_name: fileName,
                    },
                  });
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </li>
  );
}
