import {
  // UserOutlined,
  // AntDesignOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../Sales/calculator/resultModal";
// import SalesPerformanceChart from "../../../../Components/Apex-chart/salesPerformanceChart";
import { CSS } from "@dnd-kit/utilities";

import Icon from "../../../../../Components/Icon";
import { BASE_URL } from "../../../../../Consts/variables";
import useUniversalFetch from "../../../../../Hooks/useApi";
import KanbanModal from "../KanbanModal";
// import dayjs from "dayjs";
import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Spin,
  // DatePicker,
  Tooltip
} from "antd";
import moment from "moment";

const DraggableCard = ({
  item,
  showEditModal,
  showDetailModal,
  showDeleteConfirm,
  color,
}) => {
  const navigate = useNavigate();
  const { playgroundId } = useParams();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    border: `1px solid ${color}`,
    boxShadow: `0px 3px 9px 0px ${color}24`,
  };

  // console.log(item);

  function getInitials(name) {
    const words = name.trim().split(" "); // Split the name into words
    let initials = "";

    for (let i = 0; i < Math.min(words.length, 2); i++) {
      initials += words[i][0].toUpperCase(); // Take the first letter and convert it to uppercase
    }

    return initials;
  }

  const colorRanges = [
    {
      trackColor: "rgba(125, 237, 84, 1)",
    },
    {
      trackColor: "rgb(0, 247, 0)",
    },
    {
      trackColor: "rgb(19, 247, 1)",
    },
    {
      trackColor: "rgb(111, 237, 80)",
    },
    {
      trackColor: "rgb(0, 255, 0)",
    },
    {
      trackColor: "rgb(51, 255, 0)",
    },
    {
      trackColor: "rgb(102, 255, 0)",
    },
    {
      trackColor: "rgb(255, 255, 0)",
    },
    {
      trackColor: "rgb(255, 204, 0)",
    },
    {
      trackColor: "rgb(255, 153, 0)",
    },
    {
      trackColor: "rgb(255, 133, 52)",
    },
    {
      trackColor: "rgb(255, 102, 0)",
    },
    {
      trackColor: "rgb(253, 92, 37)",
    },
    {
      trackColor: "rgb(253, 92, 37)",
    },
    {
      trackColor: "rgb(253, 31, 30)",
    },
    {
      trackColor: "rgb(250, 0, 28)",
    },
  ];

  function getColor(value) {
    const index = Math.ceil((value / 100) * colorRanges.length) - 1;
    return colorRanges[index]?.trackColor;
  }

  const userData = JSON.parse(localStorage.getItem("userData"));

  const disablepermit =
    (userData?.role === "dev" ||
      userData?.role === "sales" ||
      userData?.role === "hr") &&
    !userData?.is_lead
      ? true
      : false;

  return (
    <>
      <li
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="trello_column_item"
      >
        <div className="item_wrapper">
          <div className="prpject_title">
            <h2
              onMouseDown={() => {
                navigate(`/task-playgrounds/${playgroundId}/${item?.id}`);
              }}
              style={{ color: color }}
            >
              {item?.name}
            </h2>
          </div>
          <div className="priority">
            <span>{item?.text_editor}</span>
            {item?.priority === "high" ? (
              <Icon icon="priority-1" className="icon priority_high" />
            ) : item?.priority === "medium" ? (
              <Icon icon="priority-2" className="icon priority_medium" />
            ) : item?.priority === "low" ? (
              <Icon icon="priority-3" className="icon priority_low" />
            ) : null}
          </div>
          <div className="date">
            <div className="date_wrapper">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 2.75V4.125V2.75ZM2.75 19.25V13.75V19.25ZM2.75 13.75L5.28917 13.1147C7.19987 12.6372 9.21837 12.859 10.9798 13.7399L11.0788 13.7894C12.8051 14.6522 14.7792 14.8825 16.6577 14.4403L19.5122 13.7692C19.1626 10.5708 19.161 7.34392 19.5076 4.14517L16.6567 4.81617C14.7784 5.2579 12.8047 5.02728 11.0788 4.16442L10.9798 4.11492C9.21837 3.23396 7.19987 3.01221 5.28917 3.48975L2.75 4.125M2.75 13.75V4.125V13.75Z"
                  fill={getColor(item?.passed_time || 1)}
                  fill-opacity="0.5"
                />
                <path
                  d="M2.75 2.75V4.125M2.75 4.125L5.28917 3.48975C7.19987 3.01221 9.21837 3.23396 10.9798 4.11492L11.0788 4.16442C12.8047 5.02728 14.7784 5.2579 16.6567 4.81617L19.5076 4.14517C19.161 7.34392 19.1626 10.5708 19.5122 13.7693L16.6577 14.4403C14.7792 14.8825 12.8051 14.6522 11.0788 13.7894L10.9798 13.7399C9.21837 12.859 7.19987 12.6372 5.28917 13.1148L2.75 13.75M2.75 4.125V13.75M2.75 19.25V13.75"
                  stroke={getColor(item?.passed_time || 1)}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>{moment(Number(item.deadline)).format("DD.MM.YYYY")}</span>
            </div>
            {!disablepermit && (
              <div className="action">
                <Popover
                  placement="bottomRight"
                  width
                  content={
                    <div className="popover_content">
                      <span
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          showEditModal(item);
                        }}
                      >
                        <Icon icon="Frame" />
                        <p>Edit </p>
                      </span>
                      <span
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          showDeleteConfirm(item);
                        }}
                      >
                        <Icon icon="trash" />
                        <p>Delete</p>
                      </span>
                    </div>
                  }
                >
                  <Icon icon="dots" />
                </Popover>
              </div>
            )}
          </div>
          <div className="members">
            <div className="creators">
              <Avatar.Group
                maxCount={5}
                size="small"
                maxStyle={{
                  color: "#fff",
                  background: "linear-gradient(180deg,#8BAAFC 0%,#1953E9 100%)",
                }}
              >
                {item?.employees &&
                  item?.employees.map((item) => (
                    <Tooltip title={item.fullname} placement="bottom">
                      <Avatar
                        size="small"
                        style={{
                          background:
                            "linear-gradient(180deg,#8BAAFC 0%,#1953E9 100%)",
                        }}
                      >
                        {getInitials(item?.fullname)}
                      </Avatar>
                    </Tooltip>
                  ))}
              </Avatar.Group>
            </div>
            {item?.employees.map((item) => item.id).includes(userData.id) && (
              <span className="mine">ME</span>
            )}
          </div>
        </div>
      </li>
    </>
  );
};

const Column = ({
  column,
  arr,
  index,
  items,
  showCreateModal,
  showEditModal,
  showDetailModal,
  showDeleteConfirm,
  handleEdit,
  cencelDelete,
  confirmDelete,
  handleLeft,
  handleRight,
}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  // let role = userData?.role;
  const { setNodeRef } = useDroppable({ id: column?.id });
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleOpenChange = (open) => {
    setPopoverOpen(open);
  };

  const handleButtonClick = () => {
    // Perform any action you need here
    setPopoverOpen(false); // This will close the popover
  };

  const disablepermit =
    (userData?.role === "dev" ||
      userData?.role === "sales" ||
      userData?.role === "hr") &&
    !userData?.is_lead
      ? true
      : false;

  return (
    <div className="canban_list_item" ref={setNodeRef}>
      <div className="column_info">
        <div className="column_name">
          <h3 style={{ color: `${column.color}` }}>{column.name}</h3>
          <div className="status">
            {!disablepermit && (
              <Popover
                placement="bottomRight"
                width
                open={popoverOpen}
                onOpenChange={handleOpenChange}
                content={
                  <div className="popover_content">
                    <span onClick={() => handleEdit(column)}>
                      <Icon icon="Frame" />
                      <p>Edit</p>
                    </span>
                    <span>
                      <Popconfirm
                        title="Delete the playground"
                        description="Are you sure to delete this playground?"
                        onConfirm={() => confirmDelete(column)}
                        onCancel={cencelDelete}
                        okText="Yes"
                        cancelText="No"
                        okType="danger"
                      >
                        <div className="delete">
                          <span>
                            <Icon icon="trash" />
                            <p>Delete</p>
                          </span>
                        </div>
                      </Popconfirm>
                    </span>
                    {index !== 0 && (
                      <span
                        onClick={() => {
                          handleLeft(column);
                          handleButtonClick();
                        }}
                      >
                        <Icon icon="arrow-left" />
                        <p>Left</p>
                      </span>
                    )}
                    {arr.length !== index + 1 && (
                      <span
                        onClick={() => {
                          handleRight(column);
                          handleButtonClick();
                        }}
                      >
                        <Icon icon="arrow-right" />
                        <p>Right</p>
                      </span>
                    )}
                  </div>
                }
              >
                <Icon icon="dots" onClick={handleOpenChange} />
              </Popover>
            )}
          </div>
        </div>
        {!disablepermit && (
          <button
            onClick={() => {
              showCreateModal(column?.id);
            }}
            className="add_card_btn"
          >
            New task <PlusOutlined />
          </button>
        )}
      </div>
      <SortableContext
        id={column?.id}
        items={items?.map((item) => item?.id)}
        strategy={rectSortingStrategy}
      >
        <ul className="canban_column">
          {items?.map((item) => (
            <DraggableCard
              key={item?.id}
              item={item}
              color={column?.color}
              showDeleteConfirm={showDeleteConfirm}
              showEditModal={(el) => {
                showEditModal(column, el);
              }}
              showDetailModal={(el) => {
                showDetailModal(column, el);
              }}
            />
          ))}
          {items?.length === 0 && (
            <li className="empty-column-placeholder">Drop here</li>
          )}
        </ul>
      </SortableContext>
    </div>
  );
};

const ProjectInner = () => {
  const colors = [
    {
      id: 1,
      color: "#1953E9",
      name: "Blue",
    },
    {
      id: 2,
      color: "#FFC700",
      name: "Yellow",
    },
    {
      id: 3,
      color: "#1A8400",
      name: "Green",
    },
    {
      id: 4,
      color: "#F76707",
      name: "Orange",
    },
    {
      id: 5,
      color: "#F81515",
      name: "Red",
    },
    {
      id: 6,
      color: "#D64CED",
      name: "Purple",
    },
  ];
  const { confirm } = Modal;
  const userData = JSON.parse(localStorage.getItem("userData"));

  // let role = userData?.role;
  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const {
    useFetchQuery,
    useFetchMutation,
    usePatchMutation,
    useDeleteMutation,
  } = useUniversalFetch();

  const { playgroundId } = useParams();

  const {
    data: canbanData,
    isLoading: isCanbanDataLoading,
    isSuccess: isSuccessCanbanData,
    // error: canbanDataError,
    // isError: isCanbanDataError,
  } = useFetchQuery({
    queryKey: "trellodata",
    token: token,
    url: `${BASE_URL}playground-section/find-by-playground`,
    id: `/${playgroundId}`,
  });

  const {
    data: canbanDataGetOne,
    // isLoading: isCanbanDataGetOneLoading,
    // isSuccess: isSuccessCanbanDataGetOne,
    // error: canbanDataGetOneError,
    // isError: isCanbanDataGetOneError,
  } = useFetchQuery({
    queryKey: "trellodataone",
    token: token,
    url: `${BASE_URL}playground`,
    id: `/${playgroundId}`,
  });

  const playgroundData = canbanDataGetOne?.data;

  const {
    data: canbanCreateData,
    isSuccess: isSuccessCreated,
    mutate: canbanCreate,
    isLoading: iscanbanCreateLoading,
    // error: canbanCreateError,
    isError: iscanbanCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}playground-section-task`,
    method: "POST",
    token: token,
  });

  const {
    data: canbanUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: canbanUpdate,
    isLoading: iscanbanUpdateLoading,
    error: canbanUpdateError,
    isError: iscanbanUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}playground-section-task`,
    method: "PATCH",
    token: token,
  });

  const {
    // data: DargAndDropUpdateData,
    isSuccess: isSuccessDragAndDropUpdated,
    mutate: DargAndDropUpdate,
    // isLoading: isDargAndDropUpdateLoading,
    // error: DargAndDropUpdateError,
    // isError: isDargAndDropUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}playground-section-task`,
    method: "PATCH",
    token: token,
  });

  const {
    data: canbanDeleteData,
    isSuccess: isSuccessDelete,
    mutate: canbanDelete,
    // isLoading: iscanbanDeleteLoading,
    error: canbanDeleteError,
    isError: iscanbanDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}playground-section-task`,
    method: "DELETE",
    token: token,
  });

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: canbanDeleteData?.message,
      });
    } else if (iscanbanDeleteError) {
      notification.error({
        message: "Data not deleted",
        description: canbanDeleteData?.message,
      });
    }
  }, [
    iscanbanDeleteError,
    isSuccessDelete,
    canbanDeleteData,
    canbanDeleteError,
  ]);

  useEffect(() => {
    if (isSuccessCreated) {
      handleCancel();

      notification.success({
        message: canbanCreateData?.message,
      });
    }
  }, [isSuccessCreated, canbanCreateData]);

  useEffect(() => {
    if (isSuccessUpdated) {
      handleCancel();
      notification.success({
        message: canbanUpdateData?.message,
      });
    }
  }, [isSuccessUpdated, canbanUpdateData]);

  useEffect(() => {
    if (iscanbanUpdateError) {
      notification.error({
        message: canbanUpdateError?.message,
      });
    } else if (iscanbanCreateError) {
      notification.error({
        message: canbanUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canbanUpdateError, canbanUpdateError, iscanbanUpdateError]);

  const [boardItems, setBoardItems] = useState([]);
  const [isDraged, setIsDraged] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeColumn, setActiveColumn] = useState("");
  const [modalTitle, setModalTitle] = useState("Add section");
  const [canban_status, setCanban_status] = useState(colors[0]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccessCanbanData) {
      setBoardItems(canbanData?.status === 200 ? canbanData?.data : []);
      handleCancel();
    }
  }, [canbanData, isSuccessCanbanData]);

  if (currentMessage) {
  }
  if (confirmModalVisible) {
  }

  const handleSubmit = (expenseData, isEdit, other) => {
    if (isEdit && expenseData) {
      canbanUpdate({
        id: other?.expense?.id,
        data: {
          name: expenseData?.project_name,
          deadline: String(
            expenseData.date
              ? new Date(expenseData.date).getTime()
              : expenseData.date
          ),
          order: 1,
          text_editor: expenseData?.description,
          playground_section: { id: isModalVisible },
          priority: expenseData?.priority,
          project: { id: 1 },
          employees: expenseData?.creators?.map((item) => ({ id: item })),
          files: other?.files?.map((item) => ({ id: item })),
        },
      });
    } else {
      canbanCreate({
        name: expenseData?.project_name,
        deadline: String(
          expenseData.date
            ? new Date(expenseData.date).getTime()
            : expenseData.date
        ),
        order: 1,
        text_editor: expenseData?.description,
        playground_section: { id: isModalVisible },
        priority: expenseData?.priority,
        project: { id: 1 },
        employees: expenseData?.creators?.map((item) => ({ id: item })),
        files: other?.files?.map((item) => ({ id: item })),
        tg_message_available: expenseData?.tg_message_available
      });
    }
  };

  // const handleDateChange = (date, dateString) => {
  //   if (date && date.length === 2) {
  //     const startDateInMilliseconds = date[0] ? date[0].valueOf() : null;
  //     const endDateInMilliseconds = date[1] ? date[1].valueOf() : null;
  //     setDateFrom(startDateInMilliseconds);
  //     setDateTo(endDateInMilliseconds);
  //     console.log(startDateInMilliseconds);
  //     console.log(endDateInMilliseconds);
  //   } else {
  //     setDateFrom("");
  //     setDateTo("");
  //   }
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentCard(null);
  };
  const showEditModal = (column, expense) => {
    setCurrentCard(expense);
    setIsEditMode(true);
    setIsModalVisible(column?.id);
  };

  const showCreateModal = (id) => {
    setCurrentCard(null);
    setIsEditMode(false);
    setIsModalVisible(id);
  };

  const handleOpenConfirmModal = (canban, record) => {
    setConfirmModalVisible(true);
    setCurrentMessage({ canban: canban, data: record });
  };

  const handleCloseModalConfirm = () => {
    setConfirmModalVisible(false);
  };

  if (handleCloseModalConfirm) {
  }

  const showDeleteConfirm = (project) => {
    confirm({
      title: "Are you sure delete this list ?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted Project cannot be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      className: "delete-pop-up",
      onOk() {
        canbanDelete({
          id: project.id,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveId(active?.id);
  };

  const handleDragEnd = ({ active, over }) => {
    setActiveId(null);
    if (!over) return;

    const activeColumnIndex = boardItems.findIndex((column) =>
      column.playground_section_tasks.find((item) => item.id === active.id)
    );
    const overColumnIndex = boardItems.findIndex(
      (column) =>
        column.id === over.id ||
        column.playground_section_tasks.find((item) => item.id === over.id)
    );

    if (activeColumnIndex !== overColumnIndex) {
      const activeItem = boardItems[
        activeColumnIndex
      ].playground_section_tasks.find((item) => item.id === active.id);

      const newBoardItems = [...boardItems];
      newBoardItems[activeColumnIndex].playground_section_tasks = newBoardItems[
        activeColumnIndex
      ].playground_section_tasks.filter((item) => item.id !== active.id);

      if (
        newBoardItems[overColumnIndex].playground_section_tasks.length === 0
      ) {
        newBoardItems[overColumnIndex].playground_section_tasks = [activeItem];
      } else {
        newBoardItems[overColumnIndex].playground_section_tasks.splice(
          newBoardItems[overColumnIndex].playground_section_tasks.findIndex(
            (item) => item.id === over.id
          ),
          0,
          activeItem
        );
      }

      setBoardItems(newBoardItems);
    } else {
      const newColumnItems = arrayMove(
        boardItems[activeColumnIndex].playground_section_tasks,
        boardItems[activeColumnIndex].playground_section_tasks.findIndex(
          (item) => item.id === active.id
        ),
        boardItems[overColumnIndex].playground_section_tasks.findIndex(
          (item) => item.id === over.id
        )
      );

      const newBoardItems = [...boardItems];
      newBoardItems[activeColumnIndex].playground_section_tasks =
        newColumnItems;
      setBoardItems(newBoardItems);
    }

    setIsDraged(true);
  };

  const handleDragCancel = () => setActiveId(null);
  useEffect(() => {
    const dragedItems = boardItems?.map((item) => ({
      playground_section_id: item.id,
      playground_section_tasks: item.playground_section_tasks?.map(
        (el, index) => ({
          id: el.id,
          order: index,
        })
      ),
    }));
    // setTimeout(() => {
    if (isDraged) {
      DargAndDropUpdate({
        id: "change-order-or-section",
        data: {
          orders: dragedItems,
        },
      });
    }
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraged]);

  useEffect(() => {
    if (isSuccessDragAndDropUpdated) {
      setIsDraged(false);
    }
  }, [isSuccessDragAndDropUpdated]);

  const {
    data: columnCreateData,
    isSuccess: isSuccessColumnCreated,
    mutate: columnCreate,
    // isLoading: isColumnCreateLoading,
    error: columnCreateError,
    isError: isColumnCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}playground-section`,
    method: "POST",
    token: token,
  });

  const {
    data: columnUpdateData,
    isSuccess: isSuccessColumnUpdated,
    mutate: ColumnUpdate,
    // isLoading: isColumnUpdateLoading,
    error: columnUpdateError,
    isError: isColumnUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}playground-section`,
    method: "PATCH",
    token: token,
  });

  const {
    // data: columnUpdateOrderData,
    // isSuccess: isSuccessColumnUpdateOrderd,
    mutate: ColumnUpdateOrder,
    // isLoading: isColumnUpdateOrderLoading,
    // error: columnUpdateOrderError,
    // isError: isColumnUpdateOrderError,
  } = usePatchMutation({
    url: `${BASE_URL}playground-section/change-order-left-right`,
    method: "PATCH",
    token: token,
  });

  const {
    data: columnDeleteData,
    isSuccess: isSuccessColumnDelete,
    mutate: columnDeleteMutation,
    // isLoading: isColumnDeleteLoading,
    error: columnDeleteError,
    isError: isColumnDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}playground-section`,
    method: "DELETE",
    token: token,
  });

  const confirmDelete = (column) => {
    columnDeleteMutation({
      id: column?.id,
    });
  };
  const cancel = (e) => {};

  const handleOpenModal = () => {
    setModalVisible(true);
    setModalTitle("Add section");
    setCanban_status(colors[0]);
    form.setFieldValue("project_name", "");

    setIsEdit(false);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSubmitModal = () => {
    if (!isEdit) {
      columnCreate({
        name: form.getFieldValue("project_name"),
        playground: { id: Number(playgroundId) },
        color: canban_status.color,
      });
    } else {
      ColumnUpdate({
        id: activeColumn,
        data: {
          name: form.getFieldValue("project_name"),
          color: canban_status.color,
          playground: { id: Number(playgroundId) },
        },
      });
    }
  };

  const handleOkClear = () => {
    form
      .validateFields()
      .then(() => {
        handleSubmitModal();
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const handleOpenModalEdit = (item) => {
    setModalTitle("Edit Section");
    setIsEdit(true);
    setModalVisible(true);
    setActiveColumn(item?.id);
    setCanban_status(
      colors?.find((color) => color?.color === item?.color) || colors[0]
    );

    form.setFieldValue("project_name", item?.name);
  };

  const handleRight = (item) => {
    ColumnUpdateOrder({
      id: item?.id,
      data: {
        left_right: true,
        playground_id: Number(playgroundId),
      },
    });
  };
  const handleLeft = (item) => {
    ColumnUpdateOrder({
      id: item?.id,
      data: {
        left_right: false,
        playground_id: Number(playgroundId),
      },
    });
  };

  useEffect(() => {
    if (isSuccessColumnCreated) {
      notification.success({
        // message: 'Project created',
        message: columnCreateData?.message,
      });
      setModalVisible(false);
      form.resetFields();
    } else if (isColumnCreateError) {
      notification.error({
        message: "Column not created",
        description: columnCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColumnCreateError, columnCreateData, columnCreateError]);

  useEffect(() => {
    if (isSuccessColumnDelete) {
      notification.success({
        // message: 'Project created',
        message: columnDeleteData?.message,
      });
    } else if (isColumnDeleteError) {
      notification.error({
        message: "Column not deleted",
        description: columnDeleteError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColumnDeleteError, columnDeleteData, columnDeleteError]);

  useEffect(() => {
    if (isSuccessColumnUpdated) {
      notification.success({
        // message: 'Project created',
        message: columnUpdateData?.message,
      });
      setModalVisible(false);
      form.resetFields();
    } else if (isColumnUpdateError) {
      notification.error({
        message: "Column not updated",
        description: columnUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColumnUpdateError, columnUpdateData, columnUpdateError]);

  const disablepermit =
    (userData?.role === "dev" ||
      userData?.role === "sales" ||
      userData?.role === "hr") &&
    !userData?.is_lead
      ? true
      : false;

  if (isCanbanDataLoading) {
    return (
      <div className="loader">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div className="sales canban_page_wrapper">
          <div className="page-title trello_title_wrapper">
            <div className="back-home trello_back_btn">
              <Link to={"/task-playgrounds"}>
                <div>
                  <Icon icon="drop-down" />
                  Back to main page
                </div>
              </Link>
            </div>
            <div className="title-wrapper page_title_wrapper_trello">
              <div className="upper_section_trello">
                <h1 className="uppercase">{playgroundData?.name || ""}</h1>
                {!disablepermit && (
                  <div className="add-btn">
                    <Button
                      size="large"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleOpenModal}
                    >
                      Add section
                    </Button>
                  </div>
                )}
              </div>
              {/* <p>Here you may track information about sales</p> */}
              <CustomModal
                visible={modalVisible}
                onCancel={handleCloseModal}
                onOk={handleOkClear}
                recalculate={handleCloseModal}
                footerBtn={"Cencel"}
                heading={<h1>{modalTitle}</h1>}
                titleClass={"title min-title"}
              >
                <div className="task_create_modal_wrapper">
                  <Form form={form} layout="vertical">
                    <div
                      className="single-input-item"
                      style={{ marginBottom: "20px" }}
                    >
                      <Form.Item
                        name="project_name"
                        label="Section name"
                        rules={[
                          {
                            required: true,
                            message: "Please fill the input",
                            // validateTrigger: 'onChange'
                          },
                        ]}
                        validateTrigger={["onBlur", "onSubmit", "onChange"]}
                      >
                        <Input placeholder="Write name..." />
                      </Form.Item>
                    </div>
                  </Form>
                  <span className="color_label">Color</span>
                  <div className="colors_for_playground">
                    {colors &&
                      colors?.map((item) => (
                        <span
                          onClick={() => {
                            setCanban_status(item);
                          }}
                          style={
                            canban_status?.id === item?.id
                              ? {
                                  border: `1px solid ${item?.color}`,
                                  color: "#ffffff",
                                  backgroundColor: `${item?.color}`,
                                }
                              : {
                                  border: `1px solid ${item?.color}`,
                                  color: `${item?.color}`,
                                }
                          }
                        >
                          {item?.name}
                        </span>
                      ))}
                  </div>
                </div>
              </CustomModal>
            </div>
          </div>

          <div className="canban">
            <div className="canban_list_wrapper">
              <ul className="canban_list">
                {boardItems?.map((column, index, arr) => (
                  <Column
                    showDeleteConfirm={showDeleteConfirm}
                    showDetailModal={handleOpenConfirmModal}
                    showEditModal={showEditModal}
                    handleEdit={handleOpenModalEdit}
                    showCreateModal={showCreateModal}
                    confirmDelete={confirmDelete}
                    cencelDelete={cancel}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                    key={column.id}
                    index={index}
                    arr={arr}
                    column={column}
                    items={column.playground_section_tasks}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <DragOverlay>
          {activeId ? (
            <div className="dragging-overlay-trello">
              <DraggableCard
                item={boardItems
                  ?.flatMap((column) => column?.playground_section_tasks)
                  ?.find((item) => item?.id === activeId)}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>

      <KanbanModal
        isSuccess={isSuccessCreated || isSuccessUpdated}
        isLoading={iscanbanUpdateLoading || iscanbanCreateLoading}
        expense={currentCard}
        isVisible={isModalVisible}
        onSave={handleSubmit}
        onCancel={handleCancel}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default ProjectInner;
