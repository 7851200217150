import React, { useEffect, useState } from "react";
import { Modal, Button, Switch, Input, Form } from "antd";

function CountryModal({ visible, onOk, onCancel, initialValues }) {
  const [form] = Form.useForm();
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        title: initialValues ? initialValues.name : "",
        surcharge: initialValues ? initialValues.surcharge : "",
        status: initialValues ? initialValues.is_active : true,
      });
    }
  }, [visible, initialValues, form]);

  // const formatNumber = (value) => {
  //   return value.replace(/[^\d]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // };

  const handleChangeNumber = (value) => {
    console.log(!isNaN(value));
    // let val = formatNumber(e.target.value);
    setNumber(value);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    // Allow: backspace, delete, tab, escape, enter, and navigation keys (arrow keys)
    if (key === "Backspace" || key === "Delete" || (key >= "0" && key <= "9")) {
      return;
    }
    // Prevent default behavior for non-numeric keys
    e.preventDefault();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values, initialValues?.key);
      })
      .catch((info) => {
        console.log("Validate Failed:");
      });
  };

  const modalHeader = (
    <div className="title">
      <h1>{initialValues ? "Edit Integration" : "Add Integration"}</h1>
    </div>
  );

  return (
    <Modal
      open={visible}
      destroyOnClose
      onOk={handleSubmit}
      className="small-modal"
      onCancel={() => {
        form.resetFields(); // Reset the form on cancel
        onCancel(); // Assume onCancel handles closing the modal
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Ok
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item integration">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Please input the Integration!" },
              ]}
            >
              <Input placeholder="Integration" />
            </Form.Item>
            <Form.Item
              name="surcharge"
              label="Number"
              rules={[
                {
                  required: true,
                  message: "Please enter a number",
                },
                {
                  pattern: /^\d{1,3}$/,
                  message: "Number must be between 1 and 3 digits",
                },
              ]}
            >
              <Input
                value={number}
                onChange={handleChangeNumber}
                // min="1"
                // max="999"
                maxLength={2}
                placeholder="Write number..."
                onKeyDown={onKeyDown}
              />
            </Form.Item>
            <span className="percentage-icon">%</span>
          </div>
          {initialValues && (
            <div className="single-item">
              <p>Status</p>
              <Form.Item name="status" valuePropName="checked">
                <Switch checked={true} />
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default CountryModal;
