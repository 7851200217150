import React, { useEffect } from "react";
import { Modal, Button, Switch, Input, Form } from "antd";

function CurrencyModal({ visible, onOk, onCancel, initialValues }) {
  const [form] = Form.useForm();
  // const [number, setNumber] = useState("");

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        title: initialValues ? initialValues.name : "",
        current_rate: initialValues?.rate
          ? Number(initialValues.rate)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "",
        status: initialValues ? initialValues.is_active : true,
      });
    }
  }, [visible, initialValues, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values, initialValues?.key);
      })
      .catch((info) => {
        console.log("Validate Failed:");
      });
  };

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ current_rate: val });
  };

  const modalHeader = (
    <div className="title">
      <h1>{initialValues ? "Edit Currency" : "Add Currency"}</h1>
    </div>
  );

  return (
    <Modal
      open={visible}
      destroyOnClose
      onOk={handleSubmit}
      className="small-modal"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Ok
        </Button>,
      ]}
    >
      {/* <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item">
            <p>Currency</p>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: "Please input the currency!" },
              ]}
            >
              <Input placeholder="Currency" />
            </Form.Item>
          </div>
          {initialValues && (
            <div className="single-item">
              <p>Status</p>
              <Form.Item name="status" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          )}
        </Form>
      </div> */}
      <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item integration">
            <Form.Item
              label="Currency"
              name="title"
              rules={[
                { required: true, message: "Please input the currency!" },
              ]}
            >
              <Input placeholder="Currency" />
            </Form.Item>
            <Form.Item
              name="current_rate"
              label="Current Rate"
              rules={[
                {
                  required: true,
                  message: "Please enter a rate",
                },
              ]}
            >
              <Input
                value={form.getFieldValue("current_rate")}
                onChange={handleChange}
                placeholder="Add current rate..."
                maxLength={10}
              />
            </Form.Item>
          </div>
          {initialValues && (
            <div className="single-item">
              <p>Status</p>
              <Form.Item name="status" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default CurrencyModal;
