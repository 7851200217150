import React, { useEffect } from "react";
import { Modal, Button, Input, Select, Form } from "antd";
import useUniversalFetch from "../../../../../Hooks/useApi";
import { BASE_URL } from "../../../../../Consts/variables";

function CountryModal({ visible, onOk, onCancel, values }) {
  const [form] = Form.useForm();

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery } = useUniversalFetch();

  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    // error: currencyError,
    // isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: "currency",
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : null;

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ cost: val });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:");
      });
  };

  const modalHeader = (
    <div className="title">
      <h1>{"Payment"}</h1>
    </div>
  );

  useEffect(() => {
    form.setFieldValue("method", values?.method);
    form.setFieldValue("currency", values?.currency?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, values]);

  return (
    <Modal
      open={visible}
      destroyOnClose
      onOk={handleSubmit}
      className="small-modal"
      onCancel={() => {
        form.resetFields(); // Reset the form on cancel
        onCancel(); // Assume onCancel handles closing the modal
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Ok
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item integration payment-wrapper">
            {/* <div className="single-item salary"> */}
            <div className="single-input-item">
              <Form.Item
                name="method"
                label="Method"
                rules={[
                  {
                    required: true,
                    message: "Select method",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select style={{ height: 44 }} placeholder="Select method...">
                  <Select.Option value="cash">Cash</Select.Option>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="bank">Bank</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              label="Cost"
              name="cost"
              className="salary-inner"
              rules={[
                {
                  required: true,
                  message: "Please fill",
                },
              ]}
              validateTrigger={["onBlur", "onSubmit", "onChange"]}
            >
              <Input
                value={form.getFieldValue("cost")}
                onChange={handleChange}
                placeholder="Add salary..."
                maxLength={10}
              />
            </Form.Item>

            <Form.Item
              className="currency"
              label=" "
              // value={form.getFieldValue("cost")}
              name="currency"
              rules={[
                {
                  required: true,
                  message: "required",
                },
              ]}
              validateTrigger={["onBlur", "onSubmit", "onChange"]}
            >
              <Select
                placeholder="UZS"
                style={{ width: 90 }}
                loading={isCurrencyLoading}
                disabled
              >
                {currencies
                  ?.filter((c) => c.is_active)
                  .map((activeCity) => (
                    <Select.Option key={activeCity.id} value={activeCity.id}>
                      {activeCity.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          {/* </div> */}
        </Form>
      </div>
    </Modal>
  );
}

export default CountryModal;
