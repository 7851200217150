import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Input, List, Form, notification, Modal, Spin } from "antd";
import {
  CheckOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Icon from "../../../../Components/Icon";
import useUniversalFetch from "../../../../Hooks/useApi";
import { BASE_URL } from "../../../../Consts/variables";
import moment from "moment";
import PhoneInput from "react-phone-number-input/input";

const { confirm } = Modal;
function Single() {
  const [inputValue, setInputValue] = useState("");
  // const [textColor, setTextColor] = useState("#fff");
  const { TextArea } = Input;
  const { projectId } = useParams();
  const [toDoId, setTodoId] = useState("");
  const [disable, setDisable] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");

  // Getting Single project

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, useDeleteMutation } =
    useUniversalFetch();
  const {
    data: projectsData,
    isLoading: isProjectsDataLoading,
    // error: projectsDataError,
    // isError: isProjectsDataError,
    isSuccess: isSuccesProject,
  } = useFetchQuery({
    queryKey: "projectsInfo",
    token,
    url: `${BASE_URL}project`,
    id: `/${projectId}`,
  });

  const project = projectsData?.status === 200 ? projectsData.data : null;

  useEffect(() => {
    if (!isProjectsDataLoading && projectsData && isSuccesProject) {
      // Start a 1-second delay before updating the loader state
      const timeoutId = setTimeout(() => {
        setLoader("0");
        setOpacity("1");
      }, 50);

      // Clear the timeout if the effect re-runs or unmounts
      return () => clearTimeout(timeoutId);
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectsDataLoading, projectsData]);

  // Getting toDo list

  const {
    data: todoData,
    // isLoading: isTodoDataLoading,
    // error: todoDataError,
    // isError: isTodoDataError,
  } = useFetchQuery({
    queryKey: "projectTodo",
    token: token,
    url: `${BASE_URL}project/todo/${projectId}`,
  });

  const {
    data: projectUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: ProjectUpdate,
    isLoading: isProjectUpdateLoading,
    error: projectUpdateError,
    isError: isProjectUpdateError,
  } = useFetchMutation({
    url: `${BASE_URL}project/${projectId}`,
    method: "PATCH",
    token: token,
  });

  const {
    // data: todotUpdateData,
    // isSuccess: isSuccessCheck,
    mutate: todotUpdate,
    // isLoading: isTodotUpdateLoading,
    // error: todotUpdateError,
    // isError: isTodotUpdateError,
  } = useFetchMutation({
    url: `${BASE_URL}project/todo/update/${toDoId}`,
    method: "PATCH",
    token: token,
  });

  const {
    data: todotOneUpdateData,
    isSuccess: isSuccessOneUpdated,
    mutate: todoOneUpdate,
    // isLoading: isTodoOneUpdateLoading,
    error: todoOneUpdateError,
    isError: isTodoOneUpdateError,
  } = useFetchMutation({
    url: `${BASE_URL}project/todo/update/${toDoId}`,
    method: "PATCH",
    token: token,
  });

  const {
    data: todoDeleteData,
    isSuccess: isSuccessDelete,
    mutate: todoDelete,
    // isLoading: isTodoDeleteLoading,
    error: todoDeleteError,
    isError: isTodoDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}project/todo/remove`,
    method: "DELETE",
    token: token,
  });

  const {
    data: todoCreateData,
    isSuccess: isSuccessCreate,
    mutate: todoCreate,
    // isLoading: isTodoCreateLoading,
    error: todoCreateError,
    isError: isTodoCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}project/todo/create/${projectId}`,
    method: "POST",
    token: token,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const todos = todoData?.status === 200 ? todoData.data : [];

  const handleCheckboxChange = (todoId, checked) => {
    setTodoId(todoId);
    setIsChecked(checked);
  };

  useEffect(() => {
    toDoId &&
      todotUpdate({
        is_checked: isChecked,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDoId, isChecked]);

  const handleEditTodo = () => {
    todoOneUpdate({
      name: inputValue,
    });
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData?.role === "pm" || userData?.role === "ceo") {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [userData]);

  const showDeleteConfirm = (todoId) => {
    confirm({
      title: "Are you sure delete this list ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      className: "delete-pop-up",
      onOk() {
        todoDelete({
          id: todoId,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // useEffect(()=>{
  //   deleteId &&
  // },[deleteId])

  const handleAddTodo = () => {
    if (inputValue.trim() !== "") {
      todoCreate({
        name: inputValue.trim(),
      });
    } else {
      notification.warning({
        message: "List must contain valid value",
      });
    }

    setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Updating single projects info

  useEffect(() => {
    if (isSuccessOneUpdated) {
      notification.success({
        message: todotOneUpdateData?.message,
      });
      setInputValue("");
      setIsEdit(false);
    } else if (isTodoOneUpdateError) {
      notification.error({
        message: todotOneUpdateData?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTodoOneUpdateError, todotOneUpdateData, todoOneUpdateError]);

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: todoDeleteData?.message,
      });
    } else if (isTodoDeleteError) {
      notification.error({
        message: todoDeleteData?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTodoDeleteError, todoDeleteData, todoDeleteError]);

  useEffect(() => {
    if (isSuccessCreate) {
      notification.success({
        message: todoCreateData?.message,
      });
    } else if (isTodoCreateError) {
      notification.error({
        message: todoCreateData?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTodoCreateError, todoCreateData, todoCreateError]);

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        message: projectUpdateData?.message,
      });
    } else if (isProjectUpdateError) {
      notification.error({
        message: "Project not updated",
        description: projectUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectUpdateError, projectUpdateData, projectUpdateError]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      let body = {};

      body.client_name = values.clientName;
      body.client_phone_number = values.clientPhoneNumber;
      body.description = values.description;
      body && ProjectUpdate(body);
      // form.resetFields();
    } catch (error) {
      console.error("Validation failed:");
    }
  };

  const colorRanges = [
    {
      min: 0,
      max: 6,
      status: "finished",
      trackColor: "rgba(125, 237, 84, 1)",
      bgColor: "rgba(125, 237, 84, 0.3)",
    },
    {
      min: 6,
      max: 12,
      trackColor: "rgb(0, 247, 0)",
      bgColor: "rgba(0, 247, 0, 0.3)",
    },
    {
      min: 12,
      max: 20,
      trackColor: "rgb(19, 247, 1)",
      bgColor: "rgba(19, 247, 1, 0.3)",
    },
    {
      min: 20,
      max: 26,
      trackColor: "rgb(111, 237, 80)",
      bgColor: "rgba(111, 237, 80, 0.3)",
    },
    {
      min: 26,
      max: 32,
      trackColor: "rgb(0, 255, 0)",
      bgColor: "rgba(0, 255, 0, 0.3)",
    },
    {
      min: 32,
      max: 38,
      trackColor: "rgb(51, 255, 0)",
      bgColor: "rgba(51, 255, 0, 0.3)",
    },
    {
      min: 38,
      max: 44,
      trackColor: "rgb(102, 255, 0)",
      bgColor: "rgba(102, 255, 0, 0.3)",
    },
    {
      min: 44,
      max: 50,
      trackColor: "rgb(255, 255, 0)",
      bgColor: "rgba(255, 255, 0, 0.3)",
    },
    {
      min: 50,
      max: 56,
      status: "not started",
      trackColor: "rgb(255, 204, 0)",
      bgColor: "rgba(255, 204, 0, 0.3)",
    },
    {
      min: 56,
      max: 62,
      status: "stoped",
      trackColor: "rgb(255, 153, 0)",
      bgColor: "rgba(255, 153, 0, 0.3)",
    },
    {
      min: 62,
      max: 70,
      trackColor: "rgb(255, 133, 52)",
      bgColor: "rgba(255, 133, 52, 0.3)",
    },
    {
      min: 70,
      max: 76,
      trackColor: "rgb(255, 102, 0)",
      bgColor: "rgba(255, 102, 0, 0.3)",
    },
    {
      min: 76,
      max: 82,
      trackColor: "rgb(253, 92, 37)",
      bgColor: "rgba(253, 92, 37, 0.3)",
    },
    {
      min: 82,
      max: 88,
      trackColor: "rgb(253, 92, 37)",
      bgColor: "rgba(253, 92, 37, 0.3)",
    },
    {
      min: 88,
      max: 94,
      trackColor: "rgb(253, 31, 30)",
      bgColor: "rgba(253, 31, 30, 0.3)",
    },
    {
      min: 94,
      max: 100,
      trackColor: "rgb(250, 0, 28)",
      bgColor: "rgba(250, 0, 28, 0.3)",
    },
  ];

  function getColorForProgress(progress, status) {
    const range = colorRanges.find(
      (range) => progress >= range.min && progress <= range.max
    );

    const range2 =
      status && colorRanges.find((rangeItem) => rangeItem.status === status);

    return range2
      ? range2.trackColor
      : range
      ? range.trackColor
      : "rgb(255,255,255)";
  }

  function getBgForProgress(progress) {
    const range = colorRanges.find(
      (range) => progress >= range.min && progress <= range.max
    );
    return range ? range.bgColor : "rgb(255,255,255)";
  }

  const trackedStatuses = ["finished", "stoped", "not started"];

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("clientName", project?.client_name);
    form.setFieldValue("clientPhoneNumber", project?.client_phone_number);
    form.setFieldValue("description", project?.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  const rangeColor = getColorForProgress(project?.passed_time, project?.status);
  const rangeBgColor = getBgForProgress(project?.passed_time);

  return (
    <>
      <div className="single-project" style={{ opacity: opacity }}>
        <div className="upper-section">
          <div className="back-home">
            <Link to={"/projects"}>
              <div>
                <Icon icon="drop-down" />
                Back to main page
              </div>
            </Link>
          </div>
          <div className="save-btn">
            <Button
              type="primary"
              icon={<CheckOutlined />}
              loading={isProjectUpdateLoading}
              onClick={handleSubmit}
              disabled={disable}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="main">
          <div className="left">
            <h1>{project?.name}</h1>
            <p>Detailed information about project</p>
            <div className="proccess" style={{ backgroundColor: rangeBgColor }}>
              <div
                className="proccess-line"
                style={{
                  backgroundColor: rangeColor,
                  width: `${
                    trackedStatuses.includes(project?.status)
                      ? 100
                      : project?.passed_time || 0
                  }%`,
                }}
              ></div>
              <span
                style={{
                  color: project?.passed_time > 55 ? "#fff" : "#5F5F5F",
                }}
              >
                {project?.passed_time}%
              </span>
            </div>
            <div className="dates">
              <div className="start-date">
                <Icon icon="start" />
                <span>
                  {moment(Number(project?.start)).format("DD.MM.YYYY")}
                </span>
              </div>
              <div className="completion-date">
                <Icon icon="complete" />
                <span>
                  {moment(Number(project?.deadline)).format("DD.MM.YYYY")}
                </span>
              </div>
            </div>
            <Form
              form={form}
              className="client-info"
              onSubmit={(e) => e.preventDefault()}
            >
              <label htmlFor="">
                Client name
                <Form.Item name="clientName">
                  <Input
                    placeholder=""
                    defaultValue={project?.client_name}
                    disabled={disable}
                  />
                </Form.Item>
              </label>
              <label htmlFor="">
                Client phone number
                <Form.Item name="clientPhoneNumber">
                  <PhoneInput
                    placeholder=""
                    defaultValue={project?.client_phone_number}
                    disabled={disable}
                  />
                </Form.Item>
              </label>
              <label htmlFor="">
                About
                <Form.Item name="description">
                  <TextArea
                    placeholder=""
                    defaultValue={project?.description}
                    disabled={disable}
                  />
                </Form.Item>
              </label>
            </Form>
          </div>
          <div className="right">
            <div className="todo">
              <div
                className="top"
                style={{
                  backgroundColor: todos.length ? rangeColor : "#E2E3E7",
                }}
              ></div>
              <h1>Additional information</h1>
              <List
                style={{ marginTop: "20px" }}
                bordered
                dataSource={todos}
                renderItem={(todo) => (
                  <List.Item
                    key={todo.id}
                    style={{
                      textDecoration: todo.is_checked ? "line-through" : "none",
                    }}
                    actions={
                      !disable && [
                        <div className="action">
                          <Icon
                            icon="edit"
                            onClick={() => {
                              setIsEdit(true);
                              setInputValue(todo.name);
                              setTodoId(todo.id);
                            }}
                          />
                          <Icon
                            icon="delete"
                            onClick={() => {
                              showDeleteConfirm(todo.id);
                            }}
                          />
                        </div>,
                      ]
                    }
                  >
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        disabled={disable}
                        id={`checkbox-${todo.id}`}
                        checked={todo.is_checked}
                        onClick={(e) => {
                          handleCheckboxChange(todo.id, e.target.checked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <p>{todo?.name}</p>
                    </label>
                  </List.Item>
                )}
              />
              <div className="field">
                <TextArea
                  placeholder="Title..."
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{ marginBottom: "10px" }}
                  disabled={disable}
                  rows={1}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                />
                {!isEdit ? (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={handleAddTodo}
                    disabled={disable}
                  >
                    Add info
                  </Button>
                ) : (
                  <Button
                    type="link"
                    icon={<CheckOutlined />}
                    onClick={handleEditTodo}
                    disabled={disable}
                  >
                    Edit info
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default Single;
