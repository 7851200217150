import React, { useState, useEffect } from "react";
import {
  // Input,
  // Space,
  Modal,
  // Select,
  Button,
  Table,
  Popover,
  // message,
  notification,
  Spin,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Icon from "../../../../Components/Icon";
import IncomeModal from "./IncomeModal";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";
// import Avatar from "../../../../Assets/images/user.svg";
import moment from "moment";
import { RoleController } from "../../../../Hooks/role-controller";
import { useLocation } from "react-router-dom";
// import { current } from '@reduxjs/toolkit';

const { confirm } = Modal;

const Income = () => {
  const { pathname } = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [isFileslVisible, setIsFilesVisible] = useState(false);
  // const [expenseFiles, setExpenseFiles] = useState(null);
  // const [companyValue, setCompanyValue] = useState("all");
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  // const [title, setTitle] = useState("all");
  // const [visibility, setVisibility] = useState("invisible");
  // const [expenseId, setExpenseId] = useState(null);
  // const [showSalary, setShowSalary] = useState("salary hide-salary");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const buildQueryString = () => {
    const params = new URLSearchParams({
      page: pagination.current,
      page_size: pagination.pageSize,
    });

    if (dateFrom) {
      params.append("from", dateFrom);
    }
    if (dateTo) {
      params.append("to", dateTo);
    }

    return `?${params.toString()}`;
  };

  const queryString = buildQueryString();
  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const {
    useFetchQuery,
    useFetchMutation,
    usePatchMutation,
    useDeleteMutation,
  } = useUniversalFetch();
  const {
    data: expenseData,
    isLoading: isExpenseDataLoading,
    // error: expenseDataError,
    // isError: isExpenseDataError,
    isSuccess: isSuccesExpenseData,
  } = useFetchQuery({
    queryKey: "income",
    token: token,
    url: `${BASE_URL}income`,
    id: queryString,
  });

  const expenses = expenseData ? expenseData : [];

  console.log(expenseData);

  const handleDateChange = (date, dateString) => {
    if (date && date.length === 2) {
      const startDateInMilliseconds = date[0] ? date[0].valueOf() : null;
      const endDateInMilliseconds = date[1] ? date[1].valueOf() : null;
      setDateFrom(startDateInMilliseconds);
      setDateTo(endDateInMilliseconds);
      console.log(startDateInMilliseconds);
      console.log(endDateInMilliseconds);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  };

  useEffect(() => {
    if (!isExpenseDataLoading && expenseData && isSuccesExpenseData) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseDataLoading, expenseData]);

  const {
    data: expenseCreateData,
    isSuccess: isSuccessCreated,
    mutate: expenseCreate,
    isLoading: isExpenseCreateLoading,
    error: expenseCreateError,
    isError: isExpenseCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}income`,
    method: "POST",
    token: token,
  });

  const {
    data: expenseUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: expenseUpdate,
    isLoading: isExpenseUpdateLoading,
    error: expenseUpdateError,
    isError: isExpenseUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}income`,
    method: "PATCH",
    token: token,
  });

  const {
    data: expenseDeleteData,
    isSuccess: isSuccessDelete,
    mutate: expenseDelete,
    // isLoading: isExpenseDeleteLoading,
    error: expenseDeleteError,
    isError: isExpenseDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}income`,
    method: "DELETE",
    token: token,
  });

  const showDeleteConfirm = (expenseId) => {
    confirm({
      title: "Are you sure delete this list ?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted expenses cannot be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      className: "delete-pop-up",
      onOk() {
        expenseDelete({
          id: expenseId.id,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: expenseUpdateData?.message,
      });
      setIsModalVisible(false);
    } else if (isExpenseUpdateError) {
      notification.error({
        message: "Data not updated",
        description: expenseUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseUpdateError, expenseUpdateData, expenseUpdateError]);

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: expenseDeleteData?.message,
      });
    } else if (isExpenseDeleteError) {
      notification.error({
        message: "Data not deleted",
        description: expenseDeleteError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseDeleteError, expenseDeleteData, expenseDeleteError]);

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: expenseCreateData?.message,
      });
      setIsModalVisible(false);
    } else if (isExpenseCreateError) {
      notification.error({
        message: "Data not created",
        description: expenseCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseCreateError, expenseCreateData, expenseCreateError]);

  // const { Search } = Input;

  const showEditModal = (expense) => {
    setCurrentExpense(expense);
    setIsEditMode(true);
    setIsModalVisible(true);
  };

  const showCreateModal = () => {
    setCurrentExpense(null);
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentExpense(null);
  };

  const handleSubmit = (expenseData, isEdit) => {
    if (!isEdit) {
      expenseCreate({
        project_name: expenseData.project_name,
        income_types: [
          {
            id: expenseData.project_types,
          },
        ],
        method: expenseData.method,
        date: expenseData.date
          ? String(new Date(expenseData?.date).getTime())
          : currentExpense.date,
        comment: expenseData.comment,
        cost: Number(expenseData.cost.replace(/[^\d]/g, "")),
        currency: expenseData.currency,
        sales_agent: {
          id: expenseData.sales_agent,
        },
        current_rate: Number(expenseData.current_rate.replace(/[^\d]/g, "")),
      });
    } else {
      expenseUpdate({
        id: currentExpense.id,
        data: {
          project_name: expenseData.project_name,
          income_types: [
            {
              id: expenseData.project_types,
            },
          ],
          method: expenseData.method,
          date: expenseData.date
            ? String(new Date(expenseData?.date).getTime())
            : currentExpense.date,
          comment: expenseData.comment,
          cost: Number(expenseData.cost.replace(/[^\d]/g, "")),
          currency: expenseData.currency,
          current_rate: Number(expenseData.current_rate.replace(/[^\d]/g, "")),
          sales_agent: {
            id: expenseData.sales_agent,
          },
        },
      });
    }
  };

  const handleTableChange = (pagination) => {
    // Update pagination state to reflect the new current page
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const checkAccess = RoleController[userData?.role]?.edit?.find(
    (item) => item === pathname
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      width: 180,
      render: (_, record) => <span>{record.project_name}</span>,
    },

    {
      title: "Type",
      dataIndex: "",
      width: 120,
      render: (_, record) => (
        <div className="job">
          <span className="expense-type-raw">
            {record?.income_types[0]?.name}
          </span>
        </div>
      ),
    },
    {
      title: "Current rate",
      dataIndex: "",
      width: 130,
      render: (_, record) => (
        <span>
          {record.current_rate
            ? Number(record.current_rate)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            : ""}
        </span>
      ),
    },
    {
      title: "Sales agent",
      dataIndex: "",
      width: 120,
      render: (_, record) => <span>{record?.sales_agent.fullname}</span>,
    },
    {
      title: "Date",
      dataIndex: "",
      width: 70,
      render: (_, record) => (
        <span>{moment(Number(record.date)).format("DD.MM.YYYY")}</span>
      ),
    },

    {
      title: "Comment",
      dataIndex: "",
      width: 130,
      render: (_, record) => {
        return (
          <span className="comment expense-comment">
            <Popover placement="bottom" content={record.comment}>
              <Button type="primary">{<p>{record.comment}</p>}</Button>
              <Icon icon="drop-down" />
            </Popover>
          </span>
        );
      },
    },
    {
      title: "Method",
      dataIndex: "",
      width: 100,
      render: (_, record) => (
        <span className="method-raw">{record.method}</span>
      ),
    },
    {
      title: "Cost",
      dataIndex: "",
      width: 160,
      render: (_, record) => (
        <span>
          {record.cost
            ? Number(record.cost)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
              " " +
              record?.currency
            : ""}
        </span>
      ),
    },
    checkAccess
      ? {
          title: "Edit",
          dataIndex: "edit",
          width: 85,
          render: (_, record) => (
            <span className="edit">
              <Icon icon="edit" onClick={() => showEditModal(record)} />
              <Icon icon="delete" onClick={() => showDeleteConfirm(record)} />
            </span>
          ),
        }
      : {},
  ];

  return (
    <>
      <div
        className="expenses employees projects_log"
        style={{ opacity: opacity }}
      >
        <div className="page-heading">
          <div className="page-title">
            <h1 className="">Income</h1>
            <p>Here you may track financial information</p>
          </div>
          <div className="upper-section">
            {(userData?.role === "hr" || userData?.role === "ceo") && (
              <div className="download-btn">
                <DatePicker.RangePicker
                  className="date-filter"
                  placeholder={["Start date", "Till now"]}
                  allowEmpty={[false, true]}
                  onChange={handleDateChange}
                />
                <Button
                  size="large"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showCreateModal}
                >
                  Add Income
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="table">
          <Table
            columns={columns}
            dataSource={expenseData?.data || []}
            loading={isExpenseDataLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: expenseData?.total_elements || 0, // Total records from the backend
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
            footer={() => (
              <div style={{ textAlign: "end" }}>
                <div className="total-salary">
                  <span className="amount-wrapper">
                    {expenses &&
                      expenses?.per_currency?.map((item) => (
                        <span className="">
                          {item.currency}:{" "}
                          {item?.total_cost
                            ? Number(item?.total_cost)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            : ""}
                        </span>
                      ))}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
        <IncomeModal
          isLoading={isExpenseCreateLoading || isExpenseUpdateLoading}
          expense={currentExpense}
          isVisible={isModalVisible}
          onSave={handleSubmit}
          onCancel={handleCancel}
          isEditMode={isEditMode}
        />
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
};

export default Income;
