import React, {  useEffect } from "react";
import { Input, Select, Button, Modal, Form } from "antd";
// import { CheckOutlined } from '@ant-design/icons';
// import Icon from "../../../../../Components/Icon";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../../../../Consts/variables";
import useUniversalFetch from "../../../../../Hooks/useApi";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const SupportModal = ({
  expense,
  isVisible,
  onSave,
  onCancel,
  isEditMode,
  isLoading,
}) => {
  const [form] = Form.useForm();
  // const [disableRate, setDisableRate] = useState(false);

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery } = useUniversalFetch();

  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    // error: currencyError,
    // isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: "currency",
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : null;

  // const {
  //   data: salesData,
  //   // isLoading: isSalesDataLoading,
  //   // error: salesDataError,
  //   // isError: isSalesDataError,
  //   // isSuccess: isSuccessalesData,
  // } = useFetchQuery({
  //   queryKey: "sales",
  //   token: token,
  //   url: `${BASE_URL}employee/find-by-role`,
  //   id: `?role=${"sales"}`,
  // });

  // const sales = salesData?.data ? salesData?.data : [];
  
  // const {
    // data: expenseData,
    // isLoading: isExpenseLoading,
    // error: expenseError,
    // isError: isExpenseError,
    // isSuccess: isSuccesExpense,
  // } = useFetchQuery({
  //   queryKey: "project-type",
  //   token: token,
  //   url: `${BASE_URL}setting/project-type`,
  // });
  // const expenseTypes = expenseData?.status === 200 ? expenseData.data : null;

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ cost: val });
  };

  const phoneNumberRegex = /^\+998\d{9}$/;
  console.log(expense);
  useEffect(() => {
    form.setFieldsValue(
      expense
        ? {
            project_name: expense?.project_name,
            contact: expense?.phone_number,
            pay_date: expense?.pay_date,
            comment: expense?.comment,
            cost: expense?.cost
              ? Number(expense.cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "",
            currency: expense?.currency?.id,
          }
        : {
            project_name: null,
            contact: null,
            pay_date: null,
            comment: null,
            cost: null,
            currency: null,
          }
    );
  }, [expense, form, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values, isEditMode);
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const modalHeader = (
    <div>
      <h1>{isEditMode ? "Edit Support" : "Add Support"}</h1>
      <p>
        {isEditMode
          ? "Modify the details of this support."
          : "Fill in the details for the new support."}
      </p>
    </div>
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      className="expenses-modal"
      width={990}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="form-wrapper">
          <div className="title">{modalHeader}</div>
          <div className="input-wrapper expenses-input-wrapper">
            <div className="single-input-item">
              <Form.Item
                name="project_name"
                label="Project name"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Write name..." />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="contact"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                  },
                  {
                    pattern: { phoneNumberRegex },
                    message: "+998 ** *** ** **",
                  },
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <PhoneInput placeholder="Write phone number..." />
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="pay_date"
                label="Pay date"
                rules={[
                  {
                    required: true,
                    message: "Select pay date...",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select pay date...">
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="11">11</Select.Option>
                  <Select.Option value="12">12</Select.Option>
                  <Select.Option value="13">13</Select.Option>
                  <Select.Option value="14">14</Select.Option>
                  <Select.Option value="15">15</Select.Option>
                  <Select.Option value="16">16</Select.Option>
                  <Select.Option value="17">17</Select.Option>
                  <Select.Option value="18">18</Select.Option>
                  <Select.Option value="19">19</Select.Option>
                  <Select.Option value="20">20</Select.Option>
                  <Select.Option value="21">21</Select.Option>
                  <Select.Option value="22">22</Select.Option>
                  <Select.Option value="23">23</Select.Option>
                  <Select.Option value="24">24</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="26">26</Select.Option>
                  <Select.Option value="27">27</Select.Option>
                  <Select.Option value="28">28</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="single-input-item salary">
              <Form.Item
                label="Cost"
                name="cost"
                className="salary-inner"
                rules={[
                  {
                    required: true,
                    message: "Please fill",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input
                  value={form.getFieldValue("cost")}
                  onChange={handleChange}
                  placeholder="Add salary..."
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                className="currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="UZS"
                  style={{ width: 90 }}
                  loading={isCurrencyLoading}
                >
                  {currencies
                    ?.filter((c) => c.is_active)
                    .map((activeCity) => (
                      <Select.Option key={activeCity.id} value={activeCity.id}>
                        {activeCity.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item name="comment" label="Comment">
                <Input placeholder="Write comment..." />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SupportModal;
