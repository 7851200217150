import React, { useEffect } from "react";
import { Input, DatePicker, Select, Button, Modal, Form } from "antd";
import Icon from "../../../../Components/Icon";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";

const { TextArea } = Input;

const ProjectUpdate = ({
  isVisible,
  onSave,
  onCancel,
  projectData,
  isLoading,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (projectData && isVisible) {
      form.setFieldsValue(projectData);
    }
  }, [projectData, isVisible, form]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
      form.resetFields();
    } catch (error) {
      console.error("Validation failed:");
    }
  };

  const dateFormat = "DD/MM/YYYY";

  // const onChange = (date, dateString) => {
  //   // console.log(date, dateString);
  // };

  // console.log(deadline._d);

  const deadlineMoment = moment(Number(projectData.deadline));
  const deadline = deadlineMoment.format("DD/MM/YYYY");
  const deadlineDate = String(deadline);

  form.setFieldsValue({ projectName: projectData?.name });

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      className="min-modal"
      footer={[
        <Button key="back" type="primary" onClick={handleCancel} ghost>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
          ghost
        >
          Update
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <div className="title">
          <h1>Edit project</h1>
          <p>Edit your project and save</p>
        </div>
        <Form form={form} layout="vertical">
          <div className="upper columns-2">
            <div className="single-item">
              <Form.Item
                name="projectName"
                label="Project name"
                rules={[
                  {
                    required: true,
                    message: "Please input project name",
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Project name" />
              </Form.Item>
            </div>
            <div className="single-item calendar">
              <Form.Item name="Deadline" label="Change deadline">
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  defaultValue={dayjs(deadlineDate, dateFormat)}
                />
              </Form.Item>
              <Icon icon="data" />
            </div>
            {/* <div className="single-item ">
                            <Form.Item name="changeName" label="Change name">
                                <Input placeholder='Change name'defaultValue={projectData.name}/>
                            </Form.Item>
                        </div> */}
            <div className="single-item">
              <Form.Item name="changeStatus" label="Change status">
                <Select
                  placeholder="Select status..."
                  defaultValue={projectData.status}
                >
                  <Select.Option value="not started">Not started</Select.Option>
                  <Select.Option value="inprogress">In progress</Select.Option>
                  <Select.Option value="testing">Testing</Select.Option>
                  <Select.Option value="stoped">Stopped</Select.Option>
                  <Select.Option value="finished">Finished</Select.Option>
                  <Select.Option value="archived">Archived</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="textarea">
            <Form.Item
              name="comments"
              label="Comments"
              required="true"
              rules={[
                {
                  required: true,
                  message: "Please leave the comment",
                },
              ]}
            >
              <TextArea placeholder="Add comments..." />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

ProjectUpdate.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  projectData: PropTypes.object,
};

export default ProjectUpdate;
