import React, { useEffect } from "react";
import { Modal, Button, Switch, Input, ColorPicker, Form } from "antd";

function CountryModal({ visible, onOk, onCancel, initialValues }) {
  const [form] = Form.useForm();
  // const [number, setNumber] = useState("");


  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: initialValues ? initialValues.name : "",
        color: initialValues ? initialValues.color : "#000000",
        status: initialValues ? initialValues.is_active : true,
      });
    }
  }, [visible, initialValues, form]);

 

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values, initialValues?.key);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const modalHeader = (
    <div className="title">
      <h1>{initialValues ? "Edit Canban Status" : "Add Canban Status"}</h1>
    </div>
  );

  return (
    <Modal
      open={visible}
      destroyOnClose
      onOk={handleSubmit}
      className="small-modal"
      onCancel={() => {
        form.resetFields(); // Reset the form on cancel
        onCancel(); // Assume onCancel handles closing the modal
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Ok
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item integration">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the Status!" },
              ]}
            >
              <Input placeholder="Status" />
            </Form.Item>
            <Form.Item
              name="color"
              label="Color"
              rules={[
                {
                  required: true,
                  message: "Please choose a Color",
                },
              ]}
            >
                    <ColorPicker  onChange={(e)=> {
                     form.setFieldValue("color", e.toHexString());
                    }}  size="large" value={form.getFieldValue("color")} showText />
            </Form.Item>
          </div>
          {initialValues && (
            <div className="single-item">
              <p>Status</p>
              <Form.Item name="status" valuePropName="checked">
                <Switch checked={true} />
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default CountryModal;
