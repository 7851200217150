import React from "react";
import ReactApexChart from "react-apexcharts";

const monthNumbersToNames = (months) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.map((month) => {
    const index = parseInt(month, 10) - 1;
    return monthNames[index >= 0 ? index : index + 12];
  });
};

const ApexChart = ({ chart, type }) => {
  const monthsArray = chart.map((item) => item.month.split("-")[1]);
  const expensesArray = chart.map((item) => item.total);
  const incomeArray = chart.map((item) => item.total);
  const categories = monthNumbersToNames(monthsArray);

  const chartOptions = {
    series: [
      // {
      //   name: "Outcome",
      //   data: expensesArray,
      // },
      {
        name: type === "expense" ? "Expense" : "Income",
        data: type === "expense" ? expensesArray : incomeArray,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: [type === "expense" ? "#ED4C5C" : "#1953E9"], // Colors for the series
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: [3, 3],
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value?.toLocaleString(); // Use toLocaleString to add thousand separators
          },
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="line"
          height={500}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
